import { Button, Form, Input, Modal, Radio } from 'antd';
import { FormInstance, useForm, useWatch } from 'antd/es/form/Form';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BillTypeEnum,
  RequestBillFormNames
} from '../../../Interface/PaymentInterface';

interface IProps {
  isShowRequestBillModal: boolean;
  closeModal: () => void;
  invoiceForm: Record<RequestBillFormNames, any>;
  setInvoiceForm: (values: Record<RequestBillFormNames, any>) => void
}

export const ModalRequestInvoice: FC<IProps> = ({
  isShowRequestBillModal,
  invoiceForm,
  setInvoiceForm,
  closeModal,
}) => {
  const [form]: [FormInstance<Record<RequestBillFormNames, any>>] = useForm()
  const billType: BillTypeEnum = useWatch(RequestBillFormNames.billType, form);
  const { t } = useTranslation();

  const onFinish = (values: Record<RequestBillFormNames, any>) => {
    setInvoiceForm(values)
    onClose();
  };

  const onChangeBillType = (e: any) => {
    form.setFieldsValue({
      [RequestBillFormNames.billType]: e.target.value,
    });

    if (e.target.value === BillTypeEnum.COMPANY) {
      form.validateFields([
        RequestBillFormNames.fullName,
        RequestBillFormNames.address,
        RequestBillFormNames.email,
      ]);
    } else {
      form.validateFields([
        RequestBillFormNames.taxCode,
        RequestBillFormNames.companyName,
        RequestBillFormNames.companyAddress,
        RequestBillFormNames.companyEmail,
      ]);
    }
  };

  const onClose = () => {
    closeModal();
    form.resetFields()
  };

  useEffect(() => {
    if (isShowRequestBillModal) {
      form.setFieldsValue(invoiceForm)
    }
  }, [isShowRequestBillModal])

  if (!isShowRequestBillModal) {
    return null
  }

  return (
    <Modal
      open={isShowRequestBillModal}
      onCancel={onClose}
      footer={null}
      centered
      className="modal-common request-bill-modal"
      title={t('profile.payment-request-bill')}
      width={749}
      closeIcon={<i className="fa-solid fa-xmark" />}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        autoComplete="off"
        labelAlign="left"
      >
        <div>
          <Form.Item
            name={RequestBillFormNames.billType}
            initialValue={BillTypeEnum.COMPANY}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group onChange={onChangeBillType}>
              <Radio value={BillTypeEnum.COMPANY}>
                {t('profile.payment-company-bill')}
              </Radio>

              <Radio value={BillTypeEnum.PERSONAL}>
                {t('profile.payment-personal-bill')}
              </Radio>
            </Radio.Group>
          </Form.Item>

          {billType === BillTypeEnum.COMPANY ? (
            <>
              <Form.Item
                name={RequestBillFormNames.taxCode}
                label={t('profile.payment-tax-code')}
                rules={[
                  {
                    required: true,
                    message: `${t('profile.payment-tax-code-validate')}`,
                  },
                ]}
              >
                <Input
                  placeholder={`${t('profile.payment-tax-code-placeholder')}`}
                  className="m24-input"
                  autoFocus
                  maxLength={20}
                />
              </Form.Item>

              <Form.Item
                name={RequestBillFormNames.companyName}
                label={t('profile.payment-company-name')}
                rules={[
                  {
                    required: true,
                    message: `${t('profile.payment-company-name-validate')}`,
                  },
                ]}
              >
                <Input
                  className="m24-input"
                  placeholder={`${t('profile.payment-company-name-placeholder')}`}
                />
              </Form.Item>

              <Form.Item
                name={RequestBillFormNames.companyAddress}
                label={t('profile.payment-company-address')}
                rules={[
                  {
                    required: true,
                    message: `${t('profile.payment-company-address-validate')}`,
                  },
                ]}
              >
                <Input
                  className="m24-input"
                  placeholder={`${t('profile.payment-company-address-placeholder')}`}
                />
              </Form.Item>

              <Form.Item
                name={RequestBillFormNames.companyEmail}
                label={t('profile.payment-email')}
                rules={[
                  {
                    required: true,
                    message: `${t('profile.payment-email-validate')}`,
                  },
                  {
                    type: 'email',
                    message: `${t('profile.payment-email-invalid')}`,
                  },
                ]}
              >
                <Input
                  className="m24-input"
                  placeholder={`${t('profile.payment-email-placeholder')}`}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name={RequestBillFormNames.fullName}
                label={t('profile.payment-full-name')}
                rules={[
                  {
                    required: true,
                    message: `${t('profile.payment-full-name-validate')}`,
                  },
                ]}
              >
                <Input
                  className="m24-input"
                  placeholder={`${t('profile.payment-full-name-placeholder')}`}
                  autoFocus
                />
              </Form.Item>

              <Form.Item
                name={RequestBillFormNames.address}
                label={t('profile.payment-address')}
                rules={[
                  {
                    required: true,
                    message: `${t('profile.payment-address-validate')}`,
                  },
                ]}
              >
                <Input
                  className="m24-input"
                  placeholder={`${t('profile.payment-address-placeholder')}`}
                />
              </Form.Item>

              <Form.Item
                name={RequestBillFormNames.email}
                label={t('profile.payment-email')}
                rules={[
                  {
                    required: true,
                    message: `${t('profile.payment-email-validate')}`,
                  },
                  {
                    type: 'email',
                    message: `${t('profile.payment-email-invalid')}`,
                  },
                ]}
              >
                <Input
                  className="m24-input"
                  placeholder={`${t('profile.payment-email-placeholder')}`}
                />
              </Form.Item>
            </>
          )}
        </div>

        <div className="dpl-flex justify-content-end mg-t-10">
          <Button className="mg-r-8 btn-default-rounded" onClick={onClose}>
            <i className="fa-solid fa-xmark pd-r-8" />
            {t('profile.payment-cancel')}
          </Button>

          <Form.Item shouldUpdate>
            {() => {
              const values: Record<RequestBillFormNames, any> =
                form.getFieldsValue();
              const isNotNull =
                billType === 'COMPANY'
                  ? values[RequestBillFormNames.taxCode] &&
                    values[RequestBillFormNames.companyName] &&
                    values[RequestBillFormNames.companyAddress] &&
                    values[RequestBillFormNames.companyEmail]
                  : values[RequestBillFormNames.fullName] &&
                    values[RequestBillFormNames.address] &&
                    values[RequestBillFormNames.email];
              return (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !isNotNull ||
                    form.getFieldsError().filter(({ errors} : any) => errors.length)
                      .length > 0
                  }
                  className="m24-button txt-capitalize"
                >
                  <i className="fa-solid fa-check pd-r-8" />
                  {t('profile.payment-confirm')}
                </Button>
              );
            }}
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
