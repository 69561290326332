import React from 'react'
import Paragraph from 'antd/es/typography/Paragraph'
import './style.scss'
import TooltipCustom from '../componentsAntd/Tooltip'

interface ParagraphWithCopyProps {
	children: any
	text: string
}

const ParagraphWithCopy: React.FC<ParagraphWithCopyProps> = ({
	children,
	text,
}: ParagraphWithCopyProps): JSX.Element => {
	return (
		<Paragraph
			copyable={{
				text,
				icon: [
					<TooltipCustom title={<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>}>
						<i className='far fa-copy line-h-14 w-12'></i>
					</TooltipCustom>,
					<TooltipCustom
						title={<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copied</span>}
					>
						<i className='far fa-check fsz-12 line-h-14 w-12'></i>
					</TooltipCustom>,
				],
				tooltips: [false, false],
			}}
			className='text-copy'
		>
			{children}
		</Paragraph>
	)
}

export default ParagraphWithCopy

