import * as React from "react";
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
export interface ConfirmModalProps{
    title?:string;
    content:string;
    visible:boolean;
    loading:boolean;
    className?:any;
    classButton?: string;
    iconBtn?: React.ReactNode
    onCancel:()=>void;
    onSubmit:()=>void;
}
const ConfirmModal = (props: ConfirmModalProps)=>{
    const {t} = useTranslation();
    const {title, visible, onCancel, onSubmit, content, loading, className, classButton, iconBtn} = props;
    return(
        <Modal
            className={`${className??''}`}
            title={<span style={{fontWeight: 500}}>{title?title:t('confirm.title')}</span>}
            open={visible}
            onCancel={onCancel}
            closeIcon={<i className="fa-solid fa-xmark" />}
            centered
            footer={[
                <Button
                    className={"btn-default-outlined fsz-14 line-h-22"}
                    key="cancel"
                    onClick={onCancel}
                    icon={<i className="fal fa-times mg-r-10"></i>}
                >
                    {t('button.cancel')}
                </Button>,
                <Button
                    className={`${classButton ? classButton : ''} btn-default-rounded fsz-14 line-h-22`} key="submit"
                    autoFocus={true}
                    onClick={onSubmit}
                    icon={iconBtn ? iconBtn : <i className="fal fa-check mg-r-10" />}
                    loading={loading ? loading : false}
                >
                    {t('button.submit')}
                </Button>,
            ]}
        >
            <p className={'_p-content txt-color-black'} dangerouslySetInnerHTML={{__html: content}}/>
        </Modal>
    );
}
export default ConfirmModal;
