import MainLayout from '../../components/Layout'
import { Button, Form, FormInstance, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import type { UploadFile } from 'antd/es/upload/interface'

import {
	BodyEditStoredProductInterface,
	CurrencyInterface,
	EditStoredProductFormNames,
	SKUInterface,
	StoredProductInterface,
} from '../../Interface/StoredProductInterface'
import { TransportSection } from './TransportSection'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { CommonInfoSection, RichTextFields } from './CommonInfoSection'

import { warehouseRepository } from '../../repositories/WarehouseRepository'
import { AxiosResponse } from 'axios'

import './styles.scss'
import { getBodyEditProduct } from './helpers'
import { SellInfoSection } from '../../components/EditProductForSell/SellInfoSection'
import M24Notification from '../../utils/M24Notification'
import { NavLeftBar } from '../../components/EditProductForSell/NavLeftBar'

enum SectionIds {
	COMMON_INFO = '#common-info-section',
	SELL_INFO = '#sell-info',
	TRANSPORT_INFO = '#transport-section',
}

const StoredProductEditing = () => {
	const { t } = useTranslation()
	const params: any = useParams()
	const commonInfoRef: React.MutableRefObject<any> = useRef()
	const [form]: [FormInstance<Record<EditStoredProductFormNames, any>>] = Form.useForm()

	const [loading, setLoading] = useState<boolean>(true)
	const [productImages, setProductImages] = useState<UploadFile<unknown>[]>([])
	const [isDisableSubmitButton, setIsDisableSubmitButton] = useState<boolean>(true)
	const [variantProperties, setVariantProperties] = useState<string[]>()

	const navigate: NavigateFunction = useNavigate()

	const [currencies, setCurrencies] = useState<CurrencyInterface[]>()
	useEffect(() => {
		warehouseRepository.getCurencise().then((res: AxiosResponse<any>): void => {
			setCurrencies(res.data)
		})
	}, [])

	useEffect(() => {
		warehouseRepository
			.getStoredProductDetail(params.productId)
			.then(({ data }: AxiosResponse<StoredProductInterface>) => {
				form.setFieldsValue({
					name: data.name,
					link: data.url,
					shortDescription: data.description,
					longDescription: data.detail,
					weight: data.weight,
					width: data.width,
					height: data.height,
					length: data.length,
					skus: data.skus,
				})

				setVariantProperties(data.skus && data.skus[0].variantProperties?.map((p) => p.name))

				const imgMapping: UploadFile<unknown>[] = data.productImages.map((url: string, index: number) => ({
					uid: String(index),
					name: 'image.png',
					status: 'done',
					url,
				}))
				setProductImages(imgMapping)
			})
			.finally(() => {
				setLoading(false)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onFinish = (values: Record<EditStoredProductFormNames, any>) => {
		const richTextFields: RichTextFields = commonInfoRef.current?.getCommonInfoValues()

		setLoading(true)
		const body: BodyEditStoredProductInterface = getBodyEditProduct(productImages, richTextFields, values)

		warehouseRepository
			.editStoredProduct(params.productId, body)
			.then(() => {
				navigate('/warehouse/stored-products')
			})
			.catch((error) => {
				if (error.response?.status !== 400) {
					M24Notification.notifyError(t('message.titleFailed'), t(`message.try_again`), '', 2)
				}
				setLoading(false)
			})
	}

	const getCurrencySymbol = (index: number) => {
		const skus: SKUInterface[] = form.getFieldValue('skus')
		return currencies?.find((currency: CurrencyInterface) => currency.code === skus[index].currency)?.symbol || '¥'
	}

	return (
		<MainLayout title={t('productDetail.product-details')}>
			<div className='stored-product-editing-container'>
				<NavLeftBar
					items={[
						{
							id: SectionIds.COMMON_INFO,
							title: t('productDetail.general-info-box'),
						},
						{
							id: SectionIds.SELL_INFO,
							title: t('productDetail.selling-detail-box'),
						},
						{
							id: SectionIds.TRANSPORT_INFO,
							title: t('productDetail.delivering-detail-box'),
						},
					]}
				/>

				<div className='form-container'>
					<Spin spinning={loading}>
						<Form form={form} onFinish={onFinish} onChange={() => setIsDisableSubmitButton(false)}>
							<CommonInfoSection
								ref={commonInfoRef}
								form={form}
								productImages={productImages}
								setProductImages={setProductImages}
								setIsDisableSubmitButton={setIsDisableSubmitButton}
							/>

							<SellInfoSection
								form={form}
								setIsDisableSubmitButton={setIsDisableSubmitButton}
								getCurrencySymbol={getCurrencySymbol}
								variantProperties={variantProperties}
								columnKeys={{
									salePrice: 'productDetail.sale-price',
									salePricePlaceholder: 'productDetail.sale-price-placeholder',
								}}
								setVariantProperties={setVariantProperties}
							/>

							<TransportSection form={form} />

							<div className='save-button'>
								<div className='left'></div>
								<div className='button-container'>
									<Button
										className='btn-default-rounded px-14'
										htmlType='submit'
										disabled={isDisableSubmitButton}
									>
										{t('productDetail.save-info')}
									</Button>
								</div>
							</div>
						</Form>
					</Spin>
				</div>
			</div>
		</MainLayout>
	)
}

export default StoredProductEditing

