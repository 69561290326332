import { Avatar, Button, Col, Divider, Image, Result, Row, Table } from 'antd'
import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import UboxSucceed from '../../../resources/images/cart-succeed.gif'
import EmptyDataFilter from '../../Empty/EmptyDataFilter'
import Services from '../../Services'
import './styles.scss'

type PropsStep3 = {
	orderSuccessView: any
}

function Step3(props: PropsStep3) {
	const { t } = useTranslation()
	let navigate = useNavigate()
	const { orderSuccessView } = props
	// const [successView,setSuccessView] = useState<any>()
	useEffect(() => {
		countNumber()
	}, [])

	const [totalQuantity, setTotalQuantity] = useState<number>(0)
	const [totalSkus, setTotalSkus] = useState<number>(0)
	const [services, setServices] = useState<any>([])

	const countNumber = () => {
		setTotalSkus(orderSuccessView.orderItems.length)
		let total: number = 0
		orderSuccessView.orderItems.forEach((x: any) => {
			total = total + x.orderedQuantity
			setTotalQuantity(total)
		})
	}

	const columns = [
		{
			title: <span className={'robotomedium txt-color-black txt-size-h7'}>{t('shopping-cart.seller')}</span>,
			dataIndex: 'merchantName',
			key: 'merchantName',
			width: 280,
			render: (value: any, record: any) => (
				<Row className={'whitespace flex-nowrap align-items-center'}>
					<Avatar
						size={20}
						shape={'square'}
						className={'bd0'}
						src={lodash.get(record, 'marketplace.icon', null)}
					/>
					<span className={'mgl5 robotomedium txt-color-black txt-size-h7'}>
						{lodash.get(record, 'merchantName', '--')}
					</span>
				</Row>
			),
		},
		{
			title: <span className={'robotomedium txt-color-black txt-size-h7'}>{t('shopping-cart.order-id')}</span>,
			dataIndex: 'code',
			key: 'code',
			width: 160,
			render: (value: any, record: any) => (
				<Link to={`/orders/${value}`}>
					<span className={'txt-color-blue robotomedium txt-size-h7'}>
						#{lodash.get(record, 'code', '--')}
					</span>
				</Link>
			),
		},
		{
			title: <span className={'robotomedium txt-color-black txt-size-h7'}> {t('shopping-cart.products')}</span>,
			dataIndex: 'merchantName',
			key: 'merchantName',
			width: 250,
			render: (value: any, record: any) => (
				<span>
					{' '}
					<span className={'robotoregular txt-color-black txt-size-h7'}>
						{totalQuantity} {t('shopping-cart.products')}/{totalSkus} Link
					</span>
				</span>
			),
		},
		{
			title: (
				<span className={'robotomedium txt-color-black txt-size-h7'}>{t('shopping-cart.totalPayment')}</span>
			),
			dataIndex: 'totalPayment',
			key: 'totalPayment',
			width: 130,
			render: (value: any, record: any) => {
				let currency = JSON.parse(lodash.get(record, 'provider.currency', {}))
				return (
					<span className={'robotomedium txt-size-h7 txt-color-primary'}>
						{formatMoneyByUnit(
							lodash.get(record, 'provisionalAmount', '---'),
							lodash.get(currency, 'code', '')
						)}
					</span>
				)
			},
		},
		{
			title: <span className={'robotomedium txt-color-black txt-size-h7'}>{t('shopping-cart.services')}</span>,
			dataIndex: 'services',
			key: 'services',
			width: 300,
			render: (value: any, record: any) => (
				<div className='flex align-items-center flex-wrap'>
					<Services data={record.services} className='fsz-14 line-h-22' dividerClass='divider-customize' />
				</div>
			),
		},
	]
	return (
		// <div>
		<Row justify={'space-between'} gutter={12}>
			<Col span={7} className={''}>
				<Result
					icon={<Image src={UboxSucceed} preview={false} height={250} width={250} />}
					title={
						<span className='robotobold txt-color-blue5 fsz-16 line-h-24'>
							{t('shopping-cart.order_success')}
						</span>
					}
					className='success-shopping-cart pd-24'
					extra={[
						<Button
							className={
								'btn-default-rounded robotomedium fsz-14 line-h-22 border-radius4 py-9 px-14 dpl-flex align-items-center'
							}
							onClick={() => navigate('/orders')}
							key='console'
						>
							<i className='far fa-arrow-right txt-size-h7 mg-r-10' />
							<span className={'txt-size-h7 robotomedium'}>{t('shopping-cart.to-order-management')}</span>
						</Button>,
					]}
				/>
			</Col>
			<Col span={17}>
				<div className={'flex h-fit-content bd-color-transparent success-view-custom flex-shrink-1 pd-imp-12'}>
					<Row align={'middle'} className={'pdbt16'}>
						<Avatar
							src={lodash.get(orderSuccessView, 'provider.logo', '---')}
							alt={''}
							size={24}
							style={{ border: 'none' }}
						/>
						<span className={'txt-capitalize mgl5 txt-size-h6 robotomedium txt-color-black'}>
							{lodash.get(orderSuccessView, 'provider.name', '---')}
						</span>
						<span className={'mgl5 txt-size-h6 robotomedium txt-color-black'}>
							| {lodash.get(orderSuccessView, 'customer', '---')}
						</span>
					</Row>
					<Table
						rowKey={(record: any) => record.id}
						className={'width100pc main-table main-table-no-px flex-shrink-1'}
						dataSource={[orderSuccessView]}
						columns={columns}
						pagination={false}
						locale={{ emptyText: <EmptyDataFilter description={'Opps!'} /> }}
					/>
				</div>
			</Col>
		</Row>
		// </div>
	)
}

export default Step3

