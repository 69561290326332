import { Button, Col, Form, Input, InputRef, Modal, notification, Tabs } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TAB_PRODUCT, TAB_TICKET, TAB_TRANSACTION, TAB_TRANSPORT } from '../../../../core/config'
import { ClaimInterface } from '../../../../Interface/ClaimInterface'
import ShipmentInterface, { ProductInterface } from '../../../../Interface/ShipmentInterface'
import ShipmentPackageInterface from '../../../../Interface/ShipmentPackageInterface'
import { ShipmentTransactionInterface } from '../../../../Interface/ShipmentTransaction'
import { shipmentRepository } from '../../../../repositories/ShipmentRepository'
import M24ErrorUtils from '../../../../utils/M24ErrorUtils'
import M24Notification from '../../../../utils/M24Notification'
import { getUrlPathname } from '../../../../utils/util'
import CreateProductModal from './CreateProductModal'
import Packages from './Packages'
import Products from './Products'
import Claims from './Tickets'
import Transactions from './Transactions'

const { TabPane } = Tabs

interface TabProps {
	products: ProductInterface[]
	currency: string
	currencyMarketPalace?: string
	transactions: ShipmentTransactionInterface[]
	loading: boolean
	packages: ShipmentPackageInterface[]
	loadingPackages: boolean
	claims: ClaimInterface[]
	loadingClaim: boolean
	shipment?: ShipmentInterface
	refetch: () => void
	productUpdatable: boolean
	createAbleWaybill: boolean
	shipmentStatuses: any
}
interface PropsProductTab {
	label: string
	total?: number
	isActive: boolean
}
export const CustomerTabTitle: React.FC<PropsProductTab> = ({ label, total, isActive }) => {
	return (
		<span className='px-12 pd-bt-6 dpl-flex align-items-center'>
			<span className={`mgr4 fsz-16 line-h-24 roboto${isActive ? 'medium' : 'regular'}`}>{label}</span>
			<span
				className={`dpl-flex align-items-center justify-content-center robotoregular line-h-20  px-8 pd-t-1 w-23 mg-bt-2 rad-10 ${
					isActive && total && total > 0
						? 'bg-color-orange3 txt-color-white'
						: 'bg-color-gray-tab txt-color-black'
				}`}
			>
				{total}
			</span>
		</span>
	)
}

const TabLayout: React.FC<TabProps> = (props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [form] = Form.useForm()
	const { code } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const [loadingCreateWaybill, setLoadingCreateWaybill] = useState(false)

	const [visibleCreateProduct, setVisibleCreateProduct] = useState(false)
	const [visibleCreateWaybill, setVisibleCreateWaybill] = useState(false)
	const [error, setError] = useState('')
	const inputRef = useRef<InputRef>(null)
	const [disabled, setDisabled] = useState(true)
	const [waybillFromShipment, setWaybillFromShipment] = useState<any>([])

	const {
		products,
		currency,
		currencyMarketPalace,
		transactions,
		packages,
		loadingPackages,
		loadingClaim,
		claims,
		shipment,
		refetch,
		productUpdatable,
		createAbleWaybill,
		shipmentStatuses,
	} = props
	const [currentTabKey, setCurrentTabKey] = useState('TAB_PRODUCT')
	const handleChangeTab = (activeKey: string) => {
		setCurrentTabKey(activeKey)
		setSearchParams({ tab: activeKey })
	}

	useEffect(() => {
		if (searchParams.get('tab')) {
			setCurrentTabKey(searchParams.get('tab')!)
		}
	}, [searchParams])

	useEffect(() => {
		if (visibleCreateWaybill) {
			setTimeout(() => inputRef.current!.focus({ cursor: form.getFieldValue('waybill') ? 'end' : 'start' }), 0)
		}
	}, [error, form, visibleCreateWaybill])

	const createProductHandler = () => {
		setVisibleCreateProduct(true)
	}

	const visibleCreateProductHandler = (val: boolean) => {
		setVisibleCreateProduct(val)
	}

	const fetchWaybills = useCallback(async () => {
		try {
			const res = await shipmentRepository.getWaybills(code!)
			setWaybillFromShipment(res)
		} catch (err) {
			console.log('err')
		}
	}, [code])

	useEffect(() => {
		fetchWaybills()
	}, [fetchWaybills])

	const createWayBillHandler = useCallback(
		async (force: boolean, values: any) => {
			setLoadingCreateWaybill(true)
			try {
				await shipmentRepository.createWaybill(code!, { ...values, forceCreate: force })
				notification.success({ message: t('shipment.createWaybillsSuccess') })
				refetch()
				setError('')
				setVisibleCreateWaybill(false)
				form.resetFields()
			} catch (err: any) {
				if (err.response.status !== 500) {
					setError(err.response.data.title)
				} else {
					notification.error({ message: 'Đã có lỗi xảy ra!' })
				}
				// if((err.response.data.title === 'waybill_existed' && !force) || force) {
				// 	form.resetFields()
				// }
			} finally {
				setLoadingCreateWaybill(false)
				if (force) {
					setVisibleCreateWaybill(false)
				}
				fetchWaybills()
			}
		},
		[code, t, refetch, form, fetchWaybills]
	)

	useEffect(() => {
		return () => {
			setError('')
		}
	}, [])

	return (
		<>
			<Col
				span={24}
				className='pd-12 bg-color-white box-order mgt12 txt-color-gray fsz-16 robotoregular border-radius4'
			>
				<Tabs
					activeKey={currentTabKey}
					onChange={handleChangeTab}
					tabBarExtraContent={{
						right:
							currentTabKey === TAB_TICKET ? (
								<Button
									className='btn-default-rounded mg-bt-10 fsz-14 line-h-22 py-5'
									icon={<i className='mg-r-10 fa-solid fa-plus'></i>}
									onClick={() =>
										navigate(
											`/claims/create?orderCode=${code!}&providerCode=${
												shipment?.provider
											}&customerCode=${shipment?.providerUsername}&back=${getUrlPathname(
												window.location
											)}`
										)
									}
								>
									{t('tickets.createTickets')}
								</Button>
							) : currentTabKey === TAB_PRODUCT && productUpdatable ? (
								<Button
									className='btn-default-rounded mg-bt-10 fsz-14 line-h-22 py-5'
									icon={<i className='mg-r-10 fa-solid fa-plus'></i>}
									onClick={createProductHandler}
								>
									{t('shipment.createProducts')}
								</Button>
							) : currentTabKey === TAB_TRANSPORT && createAbleWaybill ? (
								<Button
									className='btn-default-rounded mg-bt-10 fsz-14 line-h-22 py-5'
									icon={<i className='mg-r-10 fa-solid fa-plus'></i>}
									onClick={() => {
										setVisibleCreateWaybill(true)
										setError('')
									}}
								>
									{t('shipment.createWaybills')}
								</Button>
							) : null,
					}}
				>
					<TabPane
						tab={
							<CustomerTabTitle
								label={t('orderDetail.tabProduct')}
								total={products.length}
								isActive={currentTabKey === 'TAB_PRODUCT'}
							/>
						}
						key={TAB_PRODUCT}
					>
						<Products
							items={products}
							currency={currency}
							currencyMarketPalace={currencyMarketPalace}
							refetch={refetch}
							productUpdatable={productUpdatable}
						/>
					</TabPane>
					<TabPane
						tab={
							<CustomerTabTitle
								label={t('shipment.tabTransaction')}
								total={transactions.length}
								isActive={currentTabKey === TAB_TRANSACTION}
							/>
						}
						key={TAB_TRANSACTION}
					>
						<Transactions items={transactions} />
					</TabPane>
					<TabPane
						tab={
							<CustomerTabTitle
								label={t('shipment.tabPackages')}
								total={packages.length}
								isActive={currentTabKey === 'TAB_TRANSPORT'}
							/>
						}
						key={TAB_TRANSPORT}
					>
						<Packages
							fetchWaybills={fetchWaybills}
							waybillFromShipment={waybillFromShipment}
							shipmentStatuses={shipmentStatuses}
							shipment={shipment}
							packages={packages}
							loading={loadingPackages}
							waybills={shipment?.waybills}
							refetch={refetch}
						/>
					</TabPane>
					<TabPane
						tab={
							<CustomerTabTitle
								label={t('shipment.tabClaims')}
								total={claims?.length}
								isActive={currentTabKey === 'TAB_TICKET'}
							/>
						}
						key={TAB_TICKET}
					>
						<Claims items={claims} loading={loadingClaim} currency={currency} shipment={shipment} />
					</TabPane>
				</Tabs>
			</Col>
			<CreateProductModal
				visible={visibleCreateProduct}
				visibleHandler={visibleCreateProductHandler}
				refetch={refetch}
				type='create'
			/>

			<Modal
				open={visibleCreateWaybill}
				title={t('shipment.createWaybills')}
				okText={t('button.submit')}
				cancelText={t('button.cancel')}
				onCancel={() => {
					setVisibleCreateWaybill(false)
					form.resetFields()
				}}
				centered
				cancelButtonProps={{
					className: 'btn-default-outlined fsz-14 py-4 line-h-22 px-12',
					icon: <i className='fa-solid fa-xmark mg-r-10' />,
				}}
				confirmLoading={loadingCreateWaybill}
				okButtonProps={{
					className: 'btn-default-rounded fsz-14 line-h-22 py-5 px-12',
					icon: <i className='fa-solid fa-check mg-r-10'></i>,
					disabled,
				}}
				width={600}
				onOk={() => {
					form.validateFields()
						.then((values) => {
							if (!error) {
								createWayBillHandler(false, values)
							}
							if (error === 'waybill_existed_in_other_user') {
								createWayBillHandler(true, values)
							}
						})
						.catch((info) => {
							console.log('Validate Failed:', info)
						})
				}}
				className='shipment-modal-create-waybill'
			>
				<Form
					form={form}
					name='create-waybill'
					initialValues={{ waybill: '' }}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					onFieldsChange={() => {
						setDisabled(form.getFieldsError().some((field) => field.errors.length > 0) || !!error)
					}}
				>
					<Form.Item
						name='waybill'
						hasFeedback
						label={
							<span className='fsz-14 line-h-22 robotoregular txt-color-black'>
								{t('shipments.wayBill')}
							</span>
						}
						labelAlign='left'
						rules={[
							{ required: true, message: 'Không được bỏ trống!' },
							{
								// omg
								pattern: new RegExp(/^[a-zA-Z0-9_=+:.,-]+$/gm),
								message: 'Chứa kí tự không hợp lệ!',
							},
						]}
					>
						<Input
							placeholder={`${t('shipment.createWaybillsPlaceholder')}`}
							ref={inputRef}
							onChange={() => setError('')}
						/>
					</Form.Item>
				</Form>
				{error && (
					<div className={`create-waybills-${error === 'waybill_existed' ? 'error' : 'warning'}`}>
						{error === 'waybill_existed' && <i className='fa-solid fa-circle-exclamation mg-r-4 '></i>}
						{error === 'waybill_existed_in_other_user' && (
							<i
								className='fa-solid fa-triangle-exclamation mg-r-4 cursor-pointer'
								onClick={() => setError('')}
							></i>
						)}
						<span className='fsz-14 line-h-20'>{t(`shipments.${error}`)}</span>
					</div>
				)}
			</Modal>
		</>
	)
}

export default TabLayout

