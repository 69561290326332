import { Divider } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { SERVICE_NORMAL, SERVICE_PENDING, SERVICE_REJECT } from '../../core/config'
import { ServiceInterface } from '../../Interface/ShipmentInterface'
import TooltipCustom from '../componentsAntd/Tooltip'
interface ServicesProps {
	data?: ServiceInterface[]
	loading?: boolean
	className?: string
	dividerClass?: string
	wrapperClass?: string
}
const Services: React.FC<ServicesProps> = ({ data, loading, className, dividerClass, wrapperClass }) => {
	const { t } = useTranslation()
	return data && data.length > 0 ? (
		<div className={`flex flex-wrap align-items-baseline ${wrapperClass??''}`}>
			{data.map((service: ServiceInterface, idx: number) => {
				let serviceApproved = SERVICE_NORMAL
				if (service.needApprove) {
					if (service.approved === null || service.approved === undefined) {
						serviceApproved = SERVICE_PENDING
					} else if (service.approved === false) {
						serviceApproved = SERVICE_REJECT
					}
				}
				return !loading ? (
					<span className='whitespace' key={idx}>
						<TooltipCustom
						overlayClassName='service-tooltip'
							title={serviceApproved !== 'normal' ? <span className='pd-12'>{t(`service.${serviceApproved}`)}</span> : null}
							overlayInnerStyle={{ color: 'white' }}>
							<span
								className={`service px-6 ${className ?? ''} service-${serviceApproved} ${
									serviceApproved !== 'normal' ? 'cursor-pointer' : ''
								}`}>{`${service.name}`}</span>
						</TooltipCustom>
						{idx !== data.length - 1 && (
							// <span className={`service-divider ${dividerClass??''}`}>|</span>
							<Divider type='vertical' className={`mx-1 h-14 ${dividerClass?dividerClass:''}`} />
						)}
					</span>
				) : (
					<Skeleton width={80} height={20} className={`${idx !== data.length - 1 ? 'mg-r-4' : ''}`} />
				)
			})}
		</div>
	) : (
		<span>---</span>
	)
}

export default Services
