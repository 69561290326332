import { Input, notification, Spin,  Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface'
import { AxiosError } from 'axios'
import lodash from 'lodash'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoxChatContext } from '../../context/BoxChatContext'
import { MAX_UPLOAD_SIZE } from '../../core/config'
import { ThreadCommentCreateInterface } from '../../Interface/ThreadCommentCreateInterface'
import { claimRepository } from '../../repositories/ClaimRepository'
import { threadRepository } from '../../repositories/ThreadRepository'
import TooltipCustom from '../../components/componentsAntd/Tooltip'

const isImage = (file: UploadFile) => {
	if (file.type?.startsWith('image')) {
		return true
	}
	return false
}

const isVideo = (file: UploadFile) => {
	if (file.type?.startsWith('video')) {
		return true
	}
	return false
}

const MAX_UPLOAD_FILE = 10

export const MessageInputBox = () => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const boxChatContext = useContext(BoxChatContext)
	const { thread, user, onPostMessage } = boxChatContext
	const [fileList, setFileList] = useState<any[]>([])
	const [comment, setComment] = useState<any>(undefined)

	const handleChangeFile = (info: UploadChangeParam<any>) => {
		if (info.fileList.length > MAX_UPLOAD_FILE) {
			notification.error({
				message: t('chatBox.errorMaxUploadFile').replace('%s', MAX_UPLOAD_FILE.toString()),
				key: 'upload_error',
			})
			return
		}

		if (info.file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
			notification.error({
				message: t('chatBox.errorMaxUploadFileSize'),
				key: 'upload_error',
			})
			return false
		}

		setFileList([
			...info.fileList
				.filter((f: any) => f.size <= MAX_UPLOAD_SIZE * 1024 * 1024)
				.map((f: any) => {
					// @ts-ignore
					f.thumbUrl = URL.createObjectURL(f.originFileObj)
					return f
				}),
		])
	}

	const handleRemoveFile = (removedFile: UploadFile) => {
		setFileList(fileList.filter((f) => f.uid !== removedFile.uid))
	}

	const handleSubmit = () => {
		console.log(thread)
		if (!fileList.length && !comment) {
			notification.error({
				message: t('chatBox.errorCommentOrFileEmpty'),
			})
			return
		}

		setLoading(true)
		setComment(undefined)
		const postComment = lodash.clone(comment)
		let postData: ThreadCommentCreateInterface | any
		if (thread.type === 'ORDER_COMMENT') {
			postData = {
				comment: {
					author: user.username,
					content: '',
				},
			}
		} else if (thread.type === 'CLAIM_COMMENT') {
			postData = {
				comment: {
					comment: '',
					provider: thread.provider,
					providerUsername: thread.providerUsername,
				},
			}
		}

		if (fileList.length) {
			postData.attachments = fileList.map((f) => f.originFileObj)
		}

		if (postComment) {
			if (thread.type === 'ORDER_COMMENT') {
				postData.comment = {
					...postData.comment,
					content: postComment,
				}
			} else if (thread.type === 'CLAIM_COMMENT') {
				postData.comment = {
					...postData.comment,
					comment: postComment,
				}
			}
		}

		if (thread.type === 'ORDER_COMMENT') {
			threadRepository
				.createComment(thread.id, postData)
				.then((response) => {
					setComment(undefined)
					setFileList([])
					onPostMessage(response.data)
				})
				.catch((error: AxiosError) => {
					if (lodash.get(error.response, 'status') === 400) {
						if (lodash.get(error.response, 'data.title') === 'content_can_not_be_null') {
							notification.error({
								message: t('chatBox.errorCommentBlank'),
							})
						} else {
							notification.error({
								message: t('chatBox.errorCommentCreateFail'),
							})
						}
					} else {
						notification.error({
							message: t('chatBox.errorCommentCreateFail'),
						})
					}
				})
				.finally(() => setLoading(false))
		} else if (thread.type === 'CLAIM_COMMENT') {
			claimRepository
				.creatClaimComment(thread.referenceCode!, postData)
				.then((response) => {
					setComment(undefined)
					setFileList([])
					onPostMessage(response.data)
				})
				.catch((error: AxiosError) => {
					if (lodash.get(error.response, 'status') === 400) {
						if (lodash.get(error.response, 'data.title') === 'content_can_not_be_null') {
							notification.error({
								message: t('chatBox.errorCommentBlank'),
							})
						} else {
							notification.error({
								message: t('chatBox.errorCommentCreateFail'),
							})
						}
					} else {
						notification.error({
							message: t('chatBox.errorCommentCreateFail'),
						})
					}
				})
				.finally(() => setLoading(false))
		}
	}

	return (
		<Spin spinning={loading}>
			<div className={'chat-input-box'}>
				<div className='chat-input-box__attachments'>
					{fileList.map((item) => (
						<>
							{isImage(item) && (
								<span
									className={
										'chat-input-box__attachments__item chat-input-box__attachments__item--image'
									}
								>
									<img src={item.thumbUrl} alt={item.name} />
									<i
										onClick={() => handleRemoveFile(item)}
										className='chat-attachment-remove-icon fa-regular fa-circle-xmark'
									/>
									<span className='attachment-item-backdrop' />
								</span>
							)}

							{!isImage(item) && (
								<TooltipCustom title={item.name}>
									<span
										className={
											'chat-input-box__attachments__item chat-input-box__attachments__item--file'
										}
									>
										{isVideo(item) ? (
											<i className='fa-solid fa-circle-play file-icon' />
										) : (
											<i className='fa-solid fa-memo file-icon' />
										)}{' '}
										<span className={'att-file-name'}>{item.name}</span>
										<i
											onClick={() => handleRemoveFile(item)}
											className='chat-attachment-remove-icon fa-regular fa-circle-xmark'
										/>
										<span className='attachment-item-backdrop' />
									</span>
								</TooltipCustom>
							)}
						</>
					))}
				</div>

				{thread.id && (
					<div className='chat-input-box__input-container'>
						<Input.TextArea
							autoFocus={true}
							className={'chat-input-box__input'}
							placeholder={`${t('chatBox.inputPlaceHolder')}`}
							bordered={false}
							onKeyDown={(e) => {
								if (e.code === 'Enter' && !e.shiftKey) {
									handleSubmit()
								}
							}}
							value={comment}
							readOnly={loading}
							onChange={(e) => setComment(e.target.value)}
						/>
						<span className={'chat-input-box__actions'}>
							<i
								onClick={() => handleSubmit()}
								className='chat-input-box__actions__send fas fa-paper-plane'
							/>
							<Upload
								disabled={loading}
								fileList={fileList}
								beforeUpload={() => false}
								showUploadList={false}
								multiple={true}
								onChange={handleChangeFile}
							>
								<span className={'chat-input-box__actions__send-file mgl5'}>
									<i className='far fa-paperclip' />
								</span>
							</Upload>
						</span>
					</div>
				)}
			</div>
		</Spin>
	)
}

