import { Switch } from 'antd';
import React from 'react';

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const SwitchTest: React.FC = () => <>

    <Switch defaultChecked onChange={onChange} disabled/>
    <Switch defaultChecked onChange={onChange} />
</>;

export default SwitchTest;