import { Tooltip, TooltipProps } from 'antd'

export interface ExtendTooltipProps {
	children: React.ReactNode
	classnames?: string
}

type TooltipCustomProps = ExtendTooltipProps & TooltipProps

const TooltipCustom: React.FC<TooltipCustomProps> = (props: TooltipCustomProps) => {
	return (
		<Tooltip color={props.color ? props.color : '#fcd535'} {...props} className={props?.classnames}>
			{props.children}
		</Tooltip>
	)
}

export default TooltipCustom

