import { Avatar, Button, Card, Empty, Image, Radio, Row, Switch, Table } from 'antd'
import lodash from 'lodash'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { SkeletonTable, SkeletonTableColumnsType } from '../../components/SkeletonTable'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyBySuffix } from '../../core/helpers/string'
import empty from '../../resources/images/empty-blue.png'

interface ConnectionItemProps {
	currentConfigShowBalance: boolean
	getBalance: (domain: string, connectionId: any) => void
	statues: any
	data: any
	loading: boolean
	setSelected: (val: any) => void
	setTypeConfirm: (val: string) => void
	setShowConfirm: (val: boolean) => void
	setIsReconnect: (val: boolean) => void
	onShowModalAdd: () => void
	handleShowFaqsChargeCash: (provider: string, username: string) => void
	setDomain: (val: any) => void
	setProviderCode: (val: string) => void
	setProviderName: (val: string) => void
	setShowModalAdd: (val: boolean) => void
}
const ConnectionItem: React.FC<ConnectionItemProps> = ({
	data,
	currentConfigShowBalance,
	setProviderName,
	setProviderCode,
	getBalance,
	statues,
	setSelected,
	onShowModalAdd,
	setTypeConfirm,
	setShowConfirm,
	setIsReconnect,
	setDomain,
	handleShowFaqsChargeCash,
	setShowModalAdd,
	loading,
}) => {
	const { t } = useTranslation()
	const [expandConnectionProvider, setExpandConnectionProvider] = useState(true)
	const ref = useRef<HTMLDivElement | null>(null)
	const columns = [
		{
			title: <span className='robotomedium fsz-14 line-h-22'>{t('supplier.username')}</span>,
			dataIndex: 'name',
			key: 'name',
			width: 200,
			render: (text: any, record: any) => (
				<span className={'txt-size-h7 txt-color-black robotoregular'}>{text}</span>
			),
		},
		{
			title: <span className='robotomedium fsz-14 line-h-22'>{t('supplier.createdAt')}</span>,
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 200,
			render: (text: any, record: any) => (
				<span className={'txt-size-h7 txt-color-black robotoregular'}>{formatDateTime(text)}</span>
			),
		},
		{
			title: <span className='robotomedium fsz-14 line-h-22'>{t('supplier.balance')}</span>,
			dataIndex: 'balance',
			className: 'txt-right',
			key: 'balance',
			width: 200,
			render: (value: any, record: any) => {
				const currencyItem = JSON.parse(data.currency)
				return (
					<Row className={'align-items-center justify-content-end'}>
						<span className={'txt-size-h7 txt-color-black robotoregular mgr5'}>
							{record.balance != null && record.balance !== undefined
								? formatMoneyBySuffix(
										record.balance,
										lodash.get(currencyItem, 'prefix', ''),
										lodash.get(currencyItem, 'suffix', '')
								  )
								: '********'}
						</span>
						<span
							onClick={() => {
								if (
									record.status === 'CONNECTED' &&
									(record.balance == null || record.balance === undefined)
								)
									getBalance(data.domain, record.id)
							}}
							className={`txt-size-h3 ${
								record.status === 'CONNECTED' &&
								(record.balance == null || record.balance === undefined)
									? 'txt-color-orange cursor-pointer'
									: 'txt-color-gray cursor-nodrop'
							}`}
						>
							<i className='fas fa-eye'></i>
						</span>
					</Row>
				)
			},
		},
		{
			title: <span className='robotomedium fsz-14 line-h-22'>{t('supplier.status')}</span>,
			dataIndex: 'status',
			key: 'status',
			width: 250,
			render: (text: any, record: any) => {
				let status = statues.find((x: any) => x.code === record.status)
				const isManual = lodash.get(record, 'isManual')
				if (record.status !== 'INVALID')
					return (
						<Row className={'align-items-center'}>
							<Switch
								onChange={(checked) => {
									setSelected(record)
									setTypeConfirm(checked ? 'CONNECT' : 'DISCONNECT')
									setShowConfirm(true)
								}}
								checked={record.status === 'CONNECTED'}
							/>
							<span className={'txt-size-h7 txt-color-black robotoregular mgl10'}>
								{lodash.get(status, 'name', text)}
							</span>
						</Row>
					)
				else
					return (
						<Row className={''}>
							<span className={'txt-size-h7 txt-color-black robotoregular'}>
								<i className='fal fa-exclamation-triangle mgr5 txt-color-red' />
								{t('supplier.invalid-connection-error')}
							</span>
							{isManual && (
								<a
									className={'txt-size-h7 txt-color-black robotoregular mgl10 align-item-center'}
									title={`${t('supplier.reconnect')}`}
									onClick={() => {
										setSelected(record)
										setIsReconnect(true)
										setShowModalAdd(true)
									}}
								>
									<i className='fad fa-sync-alt mgr5' />
								</a>
							)}
						</Row>
					)
			},
		},
		{
			title: <span className='robotomedium fsz-14 line-h-22'>{t('supplier.actionDefault')}</span>,
			dataIndex: 'defaultConnection',
			key: 'defaultConnection',
			render: (text: any, record: any) => (
				<Row className={'align-items-center'}>
					<Radio
						onChange={(e: any) => {
							setSelected(record)
							setTypeConfirm('DEFAULT')
							setShowConfirm(true)
						}}
						disabled={record.status !== 'CONNECTED'}
						checked={record.defaultConnection}
					>
						{t('supplier.accountDefault')}
					</Radio>
				</Row>
			),
		},
		{
			title: <span className='robotomedium fsz-14 line-h-22'>{t('supplier.action')}</span>,
			dataIndex: 'action',
			key: 'action',
			render: (text: any, record: any) => (
				<Row className={'align-items-center'}>
					<Row className={'cursor-pointer'}>
						<span
							className={'txt-size-h7 txt-color-black robotoregular mgl10 btn-remove'}
							onClick={() => {
								setSelected(record)
								setTypeConfirm('DELETE')
								setShowConfirm(true)
							}}
						>
							<i className='fal fa-trash mgr5' />
							{t('supplier.actionDelete')}
						</span>
					</Row>
				</Row>
			),
		},
	]
	return (
		<Card
			key={data.code}
			headStyle={{ alignItems: 'center', paddingTop: 0 }}
			className={`border-card-header mgt12 supplier-card ${
				!expandConnectionProvider ? 'supplier-card__collapse' : ''
			}`}
			title={
				<Row
					className={'justify-content-between align-items-center'}
					onClick={() => setExpandConnectionProvider(!expandConnectionProvider)}
					ref={ref}
				>
					<Row className={'mgbt16mb'} align='middle'>
						{!loading ? (
							<Avatar src={data.logo} className='bd-none-impt' />
						) : (
							<Skeleton width={32} height={32} className='mg-r-4' />
						)}
						{!loading ? (
							<span className={'txt-size-h5 robotomedium mgl3 mgt5'}>{data.name}</span>
						) : (
							<Skeleton width={70} height={22} />
						)}
					</Row>
					<Row align='middle'>
						{data.accounts.length > 0 &&
							(!loading ? (
								<Button
									className='robotoregular  btn-default-outlined txt-color-black mg-r-12 fsz-14 line-h-22  px-14'
									onClick={(e) => {
										e.stopPropagation()
										handleShowFaqsChargeCash(
											lodash.get(data.accounts[0], 'provider.code', ''),
											lodash.get(data.accounts[0], 'name', '')
										)
									}}
								>
									{t('supplier.faq_charge_cash')}
								</Button>
							) : (
								<Skeleton width={150} height={32} className='mg-r-12' />
							))}
						{!loading ? (
							<Button
								onClick={(e) => {
									e.stopPropagation()
									setDomain(data.domain)
									onShowModalAdd()
									setProviderCode(data.code)
									setProviderName(data.name)
								}}
								icon={<i className='far fa-plus mg-r-10' />}
								className={'btn-default-rounded fsz-14 line-h-22 mg-r-24'}
							>
								{t('supplier.btAddAccount')}
							</Button>
						) : (
							<Skeleton width={150} height={32} className='mg-r-24' />
						)}
						{!loading ? (
							<span className='flex align-items-center'>
								<i
									className={`fa-solid fa-angle-${
										expandConnectionProvider ? 'up' : 'down'
									} fsz-12 mg-r-4`}
									onClick={() => setExpandConnectionProvider(!expandConnectionProvider)}
								></i>
								<span className='robotoregular txt-color-black fsz-12'>
									{t(`shipment.${expandConnectionProvider ? 'collapse' : 'noCollapse'}`)}
								</span>
							</span>
						) : (
							<Skeleton width={80} height={20} />
						)}
					</Row>
				</Row>
			}
		>
			{expandConnectionProvider && (
				<SkeletonTable
					loading={loading}
					columns={columns as SkeletonTableColumnsType[]}
					className='main-table'
					rowCount={data.accounts.length}
				>
					<Table
						rowKey={(record: any) => record.id}
						dataSource={data.accounts}
						columns={!currentConfigShowBalance ? columns.filter(({ key }) => key !== 'balance') : columns}
						pagination={false}
						className='main-table connection-supplier'
						locale={{
							emptyText: (
								<Empty
									description={
										<div className='dpl-flex flex-col align-items-center'>
											<span className='mg-r-6'>{t('supplier.empty')}</span>
											<Button
												size='middle'
												onClick={() => {
													setDomain(data.domain)
													onShowModalAdd()
													setProviderCode(data.code)
													setProviderName(data.name)
												}}
												icon={<i className='far fa-plus mg-r-10' />}
												className={'btn-default-outlined fsz-14 line-h-22 py-5 px-16 mg-t-4'}
											>
												{t('supplier.btAddAccount')}
											</Button>
										</div>
									}
									image={<Avatar shape='square' className={'icon-empty bd-none-impt'} src={empty} />}
								/>
							),
						}}
					/>
				</SkeletonTable>
			)}
		</Card>
	)
}

export default ConnectionItem

