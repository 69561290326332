import React, {Dispatch, SetStateAction, useEffect} from "react";
import {Button, Card, Checkbox, ConfigProvider, Empty, Modal, List, Spin} from "antd";
import {ConnectChannelInterface, ConnectShopInterface, ShopCriteria} from "../../../Interface/ConnectShopInterface";
import MarketIntegrations from "./MarketIntegrationList";
import {useTranslation} from "react-i18next";
import EmptyBlue from "../../../resources/images/empty-blue1.png";
import {useNavigate} from "react-router-dom";
import ShopeeTitle from "../../../resources/images/shopee.png";

const {confirm} = Modal;


type MarketChannelListProps = {
    loading: boolean;
    channels: ConnectChannelInterface[];
    integrations: ConnectShopInterface[];
    getChannelIntegrations: (filter: ShopCriteria, channelCode: string) => void;
    handleMoveToPendingList: (force?: boolean) => void;
    onCancel: () => void;
    isMoving: boolean;
    showError: boolean;
    setShowError: Dispatch<SetStateAction<any>>;
    errorContent: string;
    isCheckedList: string[];
    checkedAll: boolean;
    handleSelectCheckbox: (e: any) => void;
    handleSelectAll: (e: any) => void;
}

function MarketChannelList(props: MarketChannelListProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const validateSubmitButton = () => {
        if (props.isCheckedList && props.isCheckedList.length >= 1) return false;
        else return true;
    }

    const showConfirmModal = () => {
        confirm({
            className: 'post-to-sale-container ',
            centered: true,
            icon: null,
            title: <div>
                <i className="fa-regular fa-brake-warning mgr5 txt-color-primary"/>
                <span
                    className={'txt-size-h5 txt-color-black robotomedium'}>{t('pendingProduct.force-error-title')}</span>
            </div>,
            content: <div
                className={'dpl-flex flex-col txt-size-h7'}
            >
                {/*<span className={'robotoregular'}>{t('pendingProduct.force-error-modal')}</span>*/}
                <span className={'robotoregular mgbt5 mgt5'} dangerouslySetInnerHTML={{__html: props.errorContent}}/>
                <span className={'robotoregular'}>{t('pendingProduct.continue-force-error')}</span>
            </div>,
            cancelText: <span><i
                className="fa-solid fa-xmark mgl5 mgr10"/>{t('pendingProduct.confirm-cancelText')}</span>,
            cancelButtonProps: {className: 'btn_cancel'},
            okText: <span><i className="fa-solid fa-check mgl5 mgr10"/>{t('pendingProduct.confirm-okText')}</span>,
            okButtonProps: {className: 'btn-action-force'},
            onOk: () => {
                props.handleMoveToPendingList(true);
                props.setShowError(false);
            },
            onCancel: () => {
                props.setShowError(false);
            }
        })
    }
    useEffect(() => {
        if (props.showError) {
            showConfirmModal();
        }
    }, [props.showError])

    return (
        <div>
            <List
                bordered={false}
                className={'market-integration__channel'}
                loading={props.loading}
                dataSource={props.channels}
                renderItem={(item: ConnectChannelInterface) => {
                    return (
                        <div>
                            {props.channels && props.channels.length < 1 &&
                            <Empty
                                image={EmptyBlue}
                                imageStyle={{height: 40, color: '#3340B6'}}
                                description={
                                    <div className={'align-items-center dpl-flex justify-content-center'}>
                                        <div className={'dpl-flex flex-col'}>
                                            <span
                                                className={'txt-size-h7 robotoregular txt-color-gray4'}>{t('connectMarket.empty-list')}</span>
                                            <Button
                                                onClick={() => navigate(`/market-connect?channel=shopee`)}
                                                className={'btn-default-rounded border-radius4 mgt5'}>
                                                <span className={'mgr10'}><i
                                                    className="fa-regular fa-link txt-color-white"/></span>
                                                <span
                                                    className={'txt-size-h7 robotoregular'}>{t('connectMarket.connect-shop-btn')}</span>
                                            </Button>
                                        </div>
                                    </div>
                                }/>
                        }
                            <Card
                                bordered={false}
                                size="small"
                                title={<div className={'dpl-flex flex-row align-items-center width100pc'}>
                                    <Checkbox
                                        onClick={props.handleSelectAll}
                                        checked={props.checkedAll}
                                    >
                                        <img src={ShopeeTitle} alt={''} style={{width: 72, paddingLeft: 10}}/>
                                    </Checkbox>
                                </div>}
                            >
                                    <MarketIntegrations
                                        integrations={props.integrations}
                                        channel={item}
                                        getChannelIntegrations={props.getChannelIntegrations}
                                        handleClickCheckbox={props.handleSelectCheckbox}
                                        isCheckedList={props.isCheckedList}
                                        loading={props.loading}
                                    />
                            </Card>
                        </div>
                    )
                }}
            />
            <div className={'ant-modal-footer'}>
                <Button className={'cancel'}
                        onClick={props.onCancel}
                >
                    <i className="fa-solid fa-xmark mgl5 mgr10"/>
                    {t('storedProduct.cancel-post-to-sale-btn')}
                </Button>
                <Button
                    className={'submit'}
                    disabled={validateSubmitButton()}
                    onClick={() => props.handleMoveToPendingList()}
                    loading={props.isMoving}
                >
                    <i className="fa-solid fa-cart-plus mgl5 mgr10"/>
                    {t('storedProduct.submit-post-to-sale-btn')}
                </Button>
            </div>
        </div>
    )
}

export default MarketChannelList;
