import { Avatar, Button, Divider, Row, Table, Typography } from 'antd'
import { t } from 'i18next'
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { formatMoneyByUnit, formatNumber } from '../../../core/helpers/string'
import { FeeInterfaceInDraftShipment } from '../../../Interface/DraftShipmentInterface'
import PeerPaymentFeeInterface from '../../../Interface/PeerPaymentFeeInterface'
import PreviewFrom1688Interface from '../../../Interface/PreviewFrom1688Interface'
import { InfoShipmentAndPeerPayment } from '../../../Interface/PreviewShipmentAndPeerPaymentInterface'
import PreviewShipmentNot1688Interface from '../../../Interface/PreviewShipmentNot1688Interface'
import ShipmentAndPayOrderInterface from '../../../Interface/ShipmentAndPayInterface'
const { Paragraph } = Typography

interface ShipmentAndOrderStep3Props {
	merchant: any
	previewShipment: PreviewShipmentNot1688Interface
	info?: InfoShipmentAndPeerPayment
	preview1688: PreviewFrom1688Interface
	feePeerPayment: PeerPaymentFeeInterface
	loading: boolean
	handleCreateShipmentAndPayOrder: (body: ShipmentAndPayOrderInterface, cb: () => void) => void
	handleLoadingCreateShipmentAndOrder: (val: boolean) => void
}
const ShipmentAndOrderStep3: React.FC<ShipmentAndOrderStep3Props> = ({
	merchant,
	previewShipment,
	preview1688,
	handleCreateShipmentAndPayOrder,
	info,
	feePeerPayment,
	loading,
	handleLoadingCreateShipmentAndOrder,
}) => {
	const [foreignCash, setForeignCash] = useState(0)
	const [sumPaymentNoCarriage, setSumPaymentNoCarriage] = useState(0)
	const [sumCarriage, setSumCarriage] = useState(0)
	const [totalFeePeerPayment, setTotalFeePeerPayment] = useState(0)

	useEffect(() => {
		const orderPreviewResuslt = preview1688.orderPreviewResuslt
		if (orderPreviewResuslt) {
			setForeignCash(orderPreviewResuslt[0]?.sumPayment / 100)
			setSumPaymentNoCarriage(orderPreviewResuslt[0]?.sumPaymentNoCarriage / 100)
			setSumCarriage(orderPreviewResuslt[0]?.sumCarriage / 100)
		}
	}, [preview1688.orderPreviewResuslt])

	useEffect(() => {
		if (feePeerPayment && feePeerPayment.listFees) {
			setTotalFeePeerPayment(lodash.sumBy(feePeerPayment.listFees, 'provisionalAmount'))
		} else {
			setTotalFeePeerPayment(0)
		}
	}, [feePeerPayment])

	const columns = [
		{
			title: t('shopping-cart-with-shipment.productName'),
			render: (record: any) => (
				<div className='flex'>
					<div className='flex flex-col'>
						<div className='flex flex-nowrap align-items-center mg-bt-10'>
							<span className='robotoregular txt-color-gray4 fsz-12 line-h-20 mg-r-6 whitespace'>
								Mã đơn hàng:
							</span>
							<Paragraph className='robotomedium mg-0 txt-color-orange6  fsz-14 line-h-22 order-heading whitespace'>
								{previewShipment?.code ?? '---'}
							</Paragraph>
						</div>

						<div className='flex'>
							<Avatar
								size={40}
								shape='square'
								src={record.image ?? ''}
								className='bd-none rad-4 mg-r-8 flex-grow-1 flex-shrink-0'
							/>
							<div className='flex flex-col flex-shrink-1'>
								<div className='flex mg-bt-4'>
									<a
										href={record?.url ?? ''}
										className='robotoregular fsz-14 line-h-22 txt-color-black'>
										{record?.name ?? '---'}
									</a>
								</div>
								<div className='mg-bt-4'>
									{!!record?.variantProperties.length &&
										record?.variantProperties.map((property: any) => {
											return (
												<div className='flex flex-col mg-bt-6'>
													<span className='fsz-12 line-h-20 robotoregular txt-color-gray7'>
														{property.name ?? '---'}:{' '}
														<span className='fsz-14 line-h-22 txt-color-black robotoregular'>
															{property?.value ?? '---'}
														</span>
													</span>
												</div>
											)
										})}
								</div>
								<div className='flex align-items-center fsz-14 mg-bt-6'>
									<i className='fa-light fa-store mg-r-4'></i>
									<span className='txt-color-black mgr6 fsz-14'>
										{merchant?.merchant?.name ?? '---'}
									</span>
								</div>
								<div className='flex align-items-center fsz-14 pd-l-2'>
									<i className='fa-light fa-location-dot mg-r-4'></i>
									<span className='txt-color-black mgr6 fsz-14'>
										{merchant?.merchantContact ?? '---'}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			),
		},
		{
			title: t('shopping-cart-with-shipment.unitPrice'),
			align: 'right' as 'right',
			render: (record: any) => {
				const orderPreviewResuslt = preview1688?.orderPreviewResuslt
				let price = 0
				if (orderPreviewResuslt) {
					const cargoList = orderPreviewResuslt[0].cargoList
					const curCargo = cargoList.find((item: any) => item.specId === record.specId)
					if (curCargo) {
						price = curCargo.finalUnitPrice
					}
				}
				return (
					<span className='txt-color-black fsz-14 line-h-22 txt-color-gray4 robotomedium'>
						{info?.exchangeRate &&
						(info?.exchangeRate.rate || info?.exchangeRate.rate === 0) &&
						info?.exchangeRate.exchange
							? formatMoneyByUnit(price * info?.exchangeRate.rate, info?.exchangeRate.exchange)
							: formatMoneyByUnit(price, record.currency)}
					</span>
				)
			},
		},
		{
			title: t('shopping-cart-with-shipment.amount'),
			align: 'right' as 'right',
			render: (record: any) => (
				<span className='txt-color-black fsz-14 line-h-22 robotoregular'>
					{record.quantity ? formatNumber(record.quantity) : '---'}
				</span>
			),
		},
		{
			title: t('shopping-cart-with-shipment.toCash'),
			render: (record: any) => {
				const orderPreviewResuslt = preview1688?.orderPreviewResuslt
				let price = 0
				if (orderPreviewResuslt) {
					const cargoList = orderPreviewResuslt[0].cargoList
					const curCargo = cargoList.find((item: any) => item.specId === record.specId)
					if (curCargo) {
						price = curCargo.finalUnitPrice
					}
				}
				return (
					<span className='txt-color-black fsz-14 line-h-22 robotomedium'>
						{' '}
						{price != null && record.quantity
							? info?.exchangeRate &&
							  (info?.exchangeRate.rate || info?.exchangeRate.rate === 0) &&
							  info?.exchangeRate.exchange
								? formatMoneyByUnit(
										record.quantity * price * info?.exchangeRate.rate,
										info?.exchangeRate.exchange
								  )
								: formatMoneyByUnit(record.quantity * price, record.currency)
							: '---'}
					</span>
				)
			},
		},
	]

	const submitHandler = useCallback(() => {
		const body: any = {}
		let products: any = []
		info?.skus.forEach((sku: any) => {
			const item = {
				offerId: sku.offerId,
				specId: sku.specId,
				quantity: sku.quantity,
				skuId: sku.skuId,
			}
			products.push(item)
		})
		body.products = products
		body.shipmentCode = previewShipment.code ?? ''
		body.purchasingAccount = info?.purchaseAccount ?? ''
		const paymentCreateCommand = {
			peerPaymentProvider: info?.provider ?? '',
			peerPaymentUsername: info?.providerUsername ?? '',
			paymentMethodCode: 'alipay',
			amount: foreignCash,
			shipmentCode: previewShipment.code ?? '',
		}
		body.paymentCreateCommand = paymentCreateCommand
		handleCreateShipmentAndPayOrder(body, () => {
			handleLoadingCreateShipmentAndOrder(false)
		})
	}, [foreignCash, handleCreateShipmentAndPayOrder, info, previewShipment.code])

	return (
		<div className='flex'>
			<div className='flex flex-col mg-r-12 bg-colo-gray8 h-fit-content' style={{ flexBasis: '65%' }}>
				<div className='flex flex-col pd-16 rad-6 bg-color-white mg-bt-12'>
					<div className='flex justify-content-between align-items-center mg-bt-12'>
						<span className='fsz-14 line-h-22 robotomedium txt-color-black mg-r-12 whitespace'>
							{t('shipment-order.addressVN')}
						</span>
						<div className='line-d-h-1' />
					</div>
					<div className='flex mg-bt-12'>
						<div className='flex mg-r-12 align-items-center'>
							<i className='fa-regular fa-user mg-r-10 txt-color-gray7'></i>
							<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
								{previewShipment?.receivingWarehouse?.recipientName ?? '---'}
							</span>
						</div>
						<div className='flex align-items-center'>
							<i className='fa-regular fa-location-dot  txt-color-gray7 mg-r-10'></i>
							<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
								{previewShipment?.receivingWarehouse?.recipientAddress ?? '---'}
							</span>
						</div>
					</div>
					<div className='flex flex-col'>
						<div className='flex justify-content-between align-items-center mg-bt-12'>
							<span className='fsz-14 line-h-22 robotomedium txt-color-black mg-r-12 whitespace'>
								{t('shipment-order.addressChina')}
							</span>
							<div className='line-d-h-1' />
						</div>
						<div className='flex flex-col'>
							<div className='flex mg-r-12 align-items-center mg-bt-4'>
								<i className='fa-regular fa-user mg-r-10 txt-color-gray7'></i>
								<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
									{`${info?.deliveryAddress?.receiver ?? '---'} (${
										info?.deliveryAddress?.phoneNumber ?? '---'
									})`}
								</span>
							</div>
							<div className='flex align-items-center'>
								<i className='fa-light fa-location-dot mg-r-10 txt-color-gray7'></i>
								<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
									{info?.deliveryAddress?.address1 ?? '---'}
									{', '}
									{info?.deliveryAddress?.display ?? '---'}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-col bg-color-white pd-16 rad-6'>
					<div className='flex justify-content-between align-items-center mg-bt-16'>
						<span className='fsz-16 line-h-24 robotomedium txt-color-black mg-r-12 whitespace'>
							{t('shipment-order.infoOfOrder')}
						</span>
						<div className='line-d-h-1' />
					</div>

					<Table
						size='small'
						columns={columns}
						pagination={false}
						dataSource={info?.skus}
						className='table-peer-payment-and-order mg-bt-12'
					/>
					<div className='flex justify-content-end'>
						<div className='flex flex-col justify-content-between'>
							<div className='flex'>
								<span
									className='robotoregular txt-color-gray fsz-12 line-h-20 mg-r-100'
									style={{ width: '130px' }}>{`${t('shopping-cart-with-shipment.totalCash')}:`}</span>
								<span className='robotoregular txt-color-black fsz-14 line-h-22'>
									{`${formatMoneyByUnit(sumPaymentNoCarriage, 'CNY')}`}
								</span>
							</div>
							<div className='flex'>
								<span
									className='robotoregular txt-color-gray fsz-12 line-h-20 mg-r-100'
									style={{ width: '130px' }}>{`${t(
									'shopping-cart-with-shipment.feeNational'
								)}:`}</span>
								<span className='robotoregular txt-color-black fsz-14 line-h-22'>
									{`${
										info?.exchangeRate &&
										(info?.exchangeRate?.rate || info?.exchangeRate?.rate === 0) &&
										info?.exchangeRate?.exchange &&
										sumCarriage
											? formatMoneyByUnit(sumCarriage, 'CNY')
											: formatMoneyByUnit(sumCarriage, info?.currency)
									}`}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='flex flex-col rad-6 pd-16 box-shadow bg-color-white' style={{ flexBasis: '40%' }}>
				<div className='flex mg-bt-12 justify-content-between'>
					<span className='fsz-14 line-h-22 robotomedium txt-color-black'>Đối tác thanh toán:</span>
					<span className='fsz-14 line-h-22 robotoregular txt-color-black'>
						{/* {info.purchaseAccount ?? '---'} */}

						<Row className={'dpl-flex align-items-center'}>
							<Avatar
								size={20}
								src={lodash.get(info?.accountDefault, 'connection.provider.logo', '')}
								shape={'circle'}
								className='bd-none-impt'></Avatar>
							<span className={'mgl5 robotoregular txt-size-h7 txt-color-black'}>
								{lodash.get(info?.accountDefault, 'connection.domain', '')}
							</span>
							<Divider type={'vertical'} className={'bd-color-black'} />
							<span className={'robotoregular txt-size-h7 txt-color-black'}>
								{lodash.get(info?.accountDefault, 'connection.name', '')}
							</span>
						</Row>
					</span>
				</div>
				<div className='flex mg-bt-12 justify-content-between'>
					<span className='fsz-14 line-h-22 robotomedium txt-color-black'>
						{t('shopping-cart-with-shipment.partnerShipment')}:
					</span>
					<Row className={'dpl-flex align-items-center'}>
						<Avatar
							size={20}
							className='bd-none-impt'
							src={lodash.get(info?.accountLinkShipment, 'connection.provider.logo', '')}
							shape={'circle'}></Avatar>
						<span className={'mgl5 robotoregular txt-size-h7 txt-color-black'}>
							{lodash.get(info?.accountLinkShipment, 'connection.domain', '')}
						</span>
						<Divider type={'vertical'} className={'bd-color-black'} />
						<span className={'robotoregular txt-size-h7 txt-color-black'}>
							{lodash.get(info?.accountLinkShipment, 'connection.name', '')}
						</span>
					</Row>
				</div>
				<div className='flex mg-bt-12 justify-content-between'>
					<span className='fsz-14 line-h-22 robotoregular txt-color-gray4'>
						{t('shopping-cart-with-shipment.foreginCash')}:
					</span>
					<span className='robotoregular txt-color-black fsz-14 line-h-22'>
						{formatMoneyByUnit(foreignCash, 'CNY')}
					</span>
				</div>
				<div className='flex mg-bt-12  justify-content-between'>
					<span className='fsz-14 line-h-22 robotoregular txt-color-gray4'>
						{t('shopping-cart-with-shipment.valueTrans')}:
					</span>
					<span className='fsz-14 line-h-22 robotoregular txt-color-black'>
						{info?.exchangeRate &&
						(info?.exchangeRate?.rate || info?.exchangeRate?.rate === 0) &&
						info?.exchangeRate?.exchange &&
						foreignCash
							? formatMoneyByUnit(foreignCash * info?.exchangeRate?.rate, info?.exchangeRate?.exchange)
							: formatMoneyByUnit(foreignCash, info?.currency)}
					</span>
				</div>

				{info?.draftShipment?.fees && (
					<div className='box-fees__details bg-color-orange7 pd-12 rad-4 mg-bt-12'>
						{info?.draftShipment?.fees?.map((fee: FeeInterfaceInDraftShipment) => (
							<div key={fee.id} className='flex align-items-center justify-content-between mg-bt-12'>
								<span className='robotoregular fsz-14 line-h-22 txt-color-gray4'>
									{t(`shipments.${fee.feeType}`)}:
								</span>
								<span className='value'>
									{fee.provisionalAmount ? formatMoneyByUnit(fee.provisionalAmount, '') : '---'}
								</span>
							</div>
						))}
						{feePeerPayment &&
							feePeerPayment?.listFees?.map((fee: any) => (
								<div key={fee.id} className='flex align-items-center justify-content-between mg-bt-12'>
									<span className='robotoregular fsz-14 line-h-22 txt-color-gray4'>
										{t(`shipment-pay.${fee.feeCode}`)}:
									</span>
									<span className='value'>
										{fee.provisionalAmount ? formatMoneyByUnit(fee.provisionalAmount, '') : '---'}
									</span>
								</div>
							))}
						<div className='flex align-items-center justify-content-between'>
							<span className='robotomeidum fsz-16 line-h-24 txt-color-black'>
								{t(`shipments.totalFeesTemp`)}:
							</span>
							<span className='txt-color-blue6 robotobold fsz-14 line-h-22'>
								{info?.draftShipment?.totalFee
									? formatMoneyByUnit(info?.draftShipment?.totalFee + totalFeePeerPayment, '')
									: '---'}
							</span>
						</div>
					</div>
				)}

				<div className='bg-color-white flex flex-col pd-12 rad-4 mg-bt-24'>
					<span className='robotomeidum fsz-14 line-h-22 txt-color-black mg-bt-16'>{t('shipment-pay.infoOfOrder')}</span>
					<div className='flex justify-content-between mg-bt-12'>
						<span className='robotoregular fsz-14 line-h-22 txt-color-gray4'>{t('shipment-pay.codeOfOrder')}</span>
						<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
							{info?.refCustomerCode ? info?.refShipmentCode : '---'}
						</span>
					</div>
					<div className='flex justify-content-between mg-bt-12'>
						<span className='robotoregular fsz-14 line-h-22 txt-color-gray4'>{t('shipment-pay.codeOfCustomer')}</span>
						<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
							{info?.refShipmentCode ? info?.refCustomerCode : '---'}
						</span>
					</div>
					<div className='flex justify-content-between mg-bt-12'>
						<span className='robotoregular fsz-14 line-h-22 txt-color-gray4'>{t('shipment-pay.expectedPackages')}</span>
						<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
							{info?.expectedPackages ? info?.expectedPackages : '---'}
						</span>
					</div>
					<div className='flex justify-content-between'>
						<span className='robotoregular fsz-14 line-h-22 txt-color-gray4'>{t('shipment-pay.noteForOrder')}</span>
						<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
							{info?.employeeNote ? info?.employeeNote : '---'}
						</span>
					</div>
				</div>

				<Button
					loading={loading}
					className='btn-default-rounded robotomedium flex align-items-center flex-nowrap justify-content-center fsz-14 line-h-22'
					onClick={submitHandler}
					icon={<i className="fa-solid fa-plus mg-r-10"></i>}>
					{t('shipment-pay.submitCreateOrder')}
				</Button>
			</div>
		</div>
	)
}

export default ShipmentAndOrderStep3
