import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Badges from '../../../components/componentsAntd/Badges'
import ButtonCustom from '../../../components/componentsAntd/Button'
import TooltipCustom from '../../../components/componentsAntd/Tooltip'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
import { Button } from 'antd';
interface HeaderListProps {
	total: number
	typeDataTable: string
	setTypeDataTable: (val: string) => void
}
const HeaderList: React.FC<HeaderListProps> = (props) => {
	const { total, typeDataTable, setTypeDataTable } = props
	const navigate = useNavigate()
	const { t } = useTranslation()
	return (
		<div className='flex justify-content-between'>
			<div className='dpl-flex align-items-center'>
				<div>
					<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>{t('shipments.list')}</h3>
				</div>
				<div className='mg-l-5'>
					{total > 0 ? (
						<Badges color={'FCD535'} classnames={`px-8 py-4 fsz-12 mg-r-20`}>
							{total}
						</Badges>
					) : (
						''
					)}
				</div>
				<TooltipCustom
					title={
						<span className='robotoregular fsz-12 txt-color-white line-h-20'>
							{t('shipments.listLayout')}
						</span>
					}
				>
					<span
						className={`${
							typeDataTable === DATA_TABLE_LIST ? 'rad-4 bg-color-pink2' : ''
						} cursor-pointer mg-r-10 line-h-16  flex justify-content-center align-items-center w-26 h-26 rad-4`}
					>
						<i
							className={`${
								typeDataTable === DATA_TABLE_LIST ? 'txt-color-orange3' : ''
							} fsz-16 line-h-16  fa-light  fa-table-cells cursor-pointer txt-color-gray2`}
							onClick={() => setTypeDataTable(DATA_TABLE_LIST)}
						></i>
					</span>
				</TooltipCustom>
				<TooltipCustom
					title={
						<span className='robotoregular fsz-12 txt-color-white line-h-20'>
							{t('shipments.tableLayout')}
						</span>
					}
				>
					<span
						className={`${
							typeDataTable === DATA_TABLE_LAYOUT ? ' bg-color-pink2' : ''
						} w-26 h-26 cursor-pointer line-h-16  justify-content-center align-items-center flex justify-content-center align-items-center rad-4`}
					>
						<i
							className={`fsz-16 fa-light  fa-table-list cursor-pointer txt-color-gray2 ${
								typeDataTable === DATA_TABLE_LAYOUT ? 'txt-color-orange3' : ''
							}`}
							onClick={() => setTypeDataTable(DATA_TABLE_LAYOUT)}
						></i>
					</span>
				</TooltipCustom>
			</div>
			<Button
				className='btn-default-rounded fsz-14 line-h-22 robotomedium flex align-items-center flex-nowrap'
				icon={<i className='fa-regular fa-plus mg-r-10'></i>}
				onClick={() => navigate('/shipments/create', { replace: true })}
			>
				{t('shipments.createShipments')}
			</Button>
		</div>
	)
}

export default HeaderList

