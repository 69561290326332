import type { DatePickerProps } from 'antd'
import { DatePicker, Space } from 'antd'
import React from 'react'

const DatePickerTest: React.FC = () => {
	const onChange: DatePickerProps['onChange'] = (date, dateString) => {
		console.log(date, dateString)
	}
	return (
		<Space direction='vertical'>
			<DatePicker onChange={onChange} placeholder=''/>
		</Space>
	)
}

export default DatePickerTest
