import { Avatar, Button, DatePicker, Divider, Form, Input, Spin, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { PROFILE_KEY } from "../../core/config";
import { UserProfileInterface } from "../../Interface/UserProfileInterface";
import { userRepository } from "../../repositories/UserRepository";
import { localStorageSave } from "../../utils/LocalStorageUtils";
import M24ErrorUtils from "../../utils/M24ErrorUtils";
import M24Notification from "../../utils/M24Notification";


function UserInformation() {
    const {t} = useTranslation();
    const [userInfo] = Form.useForm();
    const [disabledSave, setDisabledSave] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [image, setImage] = React.useState<string | null>('');

    React.useEffect(() => {
        setIsLoading(true)
        userRepository.getProfile().then((response: UserProfileInterface) => {
            localStorageSave(PROFILE_KEY, response);
            setImage(response.avatar)
            userInfo.setFieldsValue({
                email: response.email,
                username: response.username,
                name: response.name,
                dob: response.dob ? moment(response.dob) : '',
                mobile: response.mobile,
                avatar: response.avatar,
            })
        }).catch((err)=> {
            M24Notification.messageError(t('message.try_again'))
        }).finally(() => setIsLoading(false));
        return ()=> {

        }
    }, [userInfo])

    const handleFormChange = () => {
        const hasErrors = userInfo.getFieldsError().some(({errors}) => errors.length);
        setDisabledSave(hasErrors);
    }

    const handleSave = async () => {
        setIsLoading(true);
        userInfo.validateFields()
            .then((values) => {
                userRepository.saveProfile({
                    ...values,
                    avatar: image,
                    name:values.name.trim(),
                }).then((response)=> {
                    localStorageSave(PROFILE_KEY,response.data);
                    window.location.reload();
                })
            })
            .then(() => {
                M24Notification.messageSuccess(t('profile.success-message'))
            })
            .catch(() => {
                M24Notification.messageError(t('profile.error-message'))
            })
            .finally(() => {
                setIsLoading(false);
                setDisabledSave(true);
            })
    }

    const beforeUpload = (file: RcFile) => {
        if (!file.type.startsWith("image")) {
            M24Notification.messageError(t('profile.upload-avatar-error'),)
            return false
        }
        setIsLoading(true)
        userRepository.uploadAvatar(file)
            .then((response) => {
                setImage(response.data);
                setDisabledSave(false);
                userInfo.validateFields().then((values) => {
                    userRepository.saveProfile({
                        ...values,
                        avatar: response.data,
                    }).then((res)=> {
                        localStorageSave(PROFILE_KEY,res.data)
                        window.location.reload();
                    })
                })
            })
            .catch((error) => {
                M24ErrorUtils.showError(t, error)
            })
            .finally(() => {
                setIsLoading(false);
            })
        return false
    }
    const onPhoneChange = (e:any) => {
        let raw = typeof e === 'string' ? e : '';
        raw = raw.replace(/[^\d,]/g, "");
        userInfo.setFieldsValue({
            ...userInfo,
            mobile:raw,
        })
    };

    const removeDob = () => {
        userInfo.setFieldsValue({
            ...userInfo,
            dob:null,
        });
        setDisabledSave(false);
    }

    return (
        <div>
            <Divider orientation="left" plain className={'profile-title'}>
                <span className={'txt-size-h3 txt-color-black robotomedium'}>{t('profile.user-info')}</span>
            </Divider>
            <div className={'pdt15'}>
                <Spin spinning={isLoading}>
                    <Form
                        className={'user-info'}
                        form={userInfo}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 14}}
                        onFieldsChange={handleFormChange}
                    >
                        <Form.Item
                            label={<span className={'pdt35'}>{t('profile.avatar')}</span>}
                            // name="avatar"
                            colon={false}
                        >
                            <Avatar size={64} src={image} className={'bd-none-impt'}/>
                            <Upload className={'mgl5 txt-size-h7 robotoregular cursor-pointer upload-btn'}
                                    accept={"image/*"}
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                            >
                                {t('profile.change-avatar')}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name='email'
                            label={t('profile.email')}
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            name='username'
                            label={t('profile.username')}
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            name='name'
                            label={t('profile.fullName')}
                            rules={[
                                // eslint-disable-next-line no-template-curly-in-string
                                {required: true, message: `${t('profile.require-message', {value: '${label}'})}`},
                                {pattern:new RegExp('^\\S+[a-zA-Z0-9-_\\\\s]'),message:''},
                            ]}
                        >
                            <Input onPressEnter={handleSave}
                                   maxLength={25}
                            />
                        </Form.Item>
                        <Form.Item
                            name='dob'
                            label={t('profile.dob')}
                        >
                            <DatePicker className={'width100pc'}
                                        format={"DD/MM/YYYY"}
                                        allowClear={false}
                                        disabledDate={(current) => {
                                            let customDate = moment().format("YYYY-MM-DD");
                                            return current && current > moment(customDate, "YYYY-MM-DD");
                                        }}
                                        inputReadOnly={true}
                                        suffixIcon={<i className="far fa-calendar-alt txt-color-gray4" style={{fontSize:14}}/>}
                            />
                            {/*<Button onClick={removeDob}>Remove DOB</Button>*/}
                        </Form.Item>
                        <Form.Item
                            name='mobile'
                            label={t('profile.mobile')}
                        >
                            <Input
                                onPressEnter={handleSave}
                                onChange={(e)=> onPhoneChange(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{offset: 4, span: 18}}>
                            <Button className={'btn-default-rounded px-14 flr'}
                                    disabled={disabledSave}
                                    onClick={handleSave}
                            >
                                {t('profile.save-info')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        </div>
    );
}

export default UserInformation;
