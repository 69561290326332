import { Button, ButtonProps } from 'antd'
export interface ExtendedButtonProps {
	color?: string
	children: React.ReactNode
	classnames?: string
}
type ButtonCustomProps = ExtendedButtonProps & ButtonProps

const ButtonCustom: React.FC<ButtonCustomProps> = (props: ButtonCustomProps) => {
	return (
		<Button
			// style={{ backgroundColor: `${props.color ? props.color : '#FCD535'}` }}
			{...props}
			// type="default"
			className={props.className}
		>
			{props.children}
		</Button>
	)
}
export default ButtonCustom

