import React, { useEffect, useState } from 'react'
import { Button, Modal, Row, Table, Tag, Col, Radio } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { cartRepository } from '../../../repositories/CartRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import ConfirmModal from '../../Modal/ConfirmModal'

import './styles.scss'

export type Props = {
	isVisible: any
	onCancel: () => void
	addressList: any
	getAddressList: (isReset?: boolean) => void
	openAddressCreate: any
	makeDeliveryAddress: (record: any) => void
	deliveryAddress: any
	updateAddress: (item: any) => void
}

function Address(props: Props) {
	const { t } = useTranslation()
	const {
		isVisible,
		onCancel,
		addressList,
		getAddressList,
		openAddressCreate,
		makeDeliveryAddress,
		deliveryAddress,
		updateAddress,
	} = props
	const [isDelete, setIsDelete] = useState<boolean>(false)
	const [selectedItem, setSelectedItem] = useState<any>(deliveryAddress)
	const [deleteItem, setDeleteItem] = useState<any>()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		setSelectedItem(deliveryAddress)
	}, [deliveryAddress])
	const onCancelDelete = () => {
		setDeleteItem(null)
		setIsDelete(false)
	}

	const deleteAddress = () => {
		setIsLoading(true)
		cartRepository
			.deleteAddress(deleteItem.id)
			.then((res) => {
				M24Notification.messageSuccess(t('message.success'))
				getAddressList(deliveryAddress?.id === deleteItem?.id)
				setDeleteItem(null)
				setIsDelete(false)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const columns = [
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			width: 40,
			render: (value: any, record: any) => {
				return (
					<Radio
						checked={selectedItem?.id === value}
						onChange={(e) => {
							setSelectedItem(record)
						}}
					/>
				)
			},
		},
		{
			title: <span className={'txt-centermb'}>{t('address.customer-name')}</span>,
			dataIndex: 'receiver',
			key: 'receiver',
			width: 160,
			render: (value: any, record: any) => {
				return <span className={'txt-size-h7 robotomedium'}>{record.receiver}</span>
			},
		},
		{
			title: t('address.customer-phoneNumber'),
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			width: 130,
			render: (value: any, record: any) => {
				return <span className={'txt-size-h7 robotomedium'}>{record.phoneNumber}</span>
			},
		},
		{
			title: t('address.address-name'),
			dataIndex: 'name',
			key: 'name',
			width: 120,
			render: (value: any, record: any) => {
				return <span className={'txt-size-h7 robotomedium'}>{record.name}</span>
			},
		},
		{
			title: t('address.address-descriptions'),
			dataIndex: 'detail',
			key: 'detail',
			width: 450,
			render: (value: any, record: any) => {
				return (
					<div className='flex align-items-center'>
						<div className='flex flex-col mg-r-94'>
							<span className={'txt-size-h7 robotoregular'}>{record.address1}</span>
							<span className={'txt-size-h7 robotoregular txt-color-gray4'}>{record.display}</span>
						</div>
						{record.isDefault ? (
							<Tag
								className={'rad-23 px-12 bd-none'}
								style={{ backgroundColor: 'orange' }}
								color={'orange'}
							>
								<span className={'txt-size-h7 robotoregular txt-color-white'}>
									{t('address.status-isDefault')}
								</span>
							</Tag>
						) : null}
					</div>
				)
			},
		},
		{
			title: <span className={'flr txt-size-h7 pdr0'}>{t('address.address-actions')}</span>,
			dataIndex: 'action',
			key: 'action',
			width: 130,
			render: (value: any, record: any) => {
				return (
					<div className='flex align-items-center justify-content-end'>
						<Button
							type={'link'}
							className={'_btn-edit-address robotoregular fsz-14 line-h-22 px-0 mg-r-12'}
							onClick={() => {
								updateAddress(record)
							}}
						>
							{t('address.edit-action')}
						</Button>
						<Button
							type={'text'}
							disabled={record.isDefault}
							onClick={() => {
								setDeleteItem(record)
								setIsDelete(true)
							}}
							className={
								'_btn-delete-address address-btn-custom txt-color-red robotoregular fsz-14 line-h-22 px-0'
							}
						>
							{t('address.delete-action')}
						</Button>
					</div>
				)
			},
		},
	]
	return (
		<>
			<Modal
				className={'_address-table-custom'}
				open={isVisible}
				centered
				title={
					<>
						<span className={'txt-size-h5 line-h-24 robotoregular mg-r-5'}>
							{t('address.address-list')}
						</span>
						<span className={'count fsz-12 px-8 bg-color-pink2 rad-66 txt-color-orange3'}>
							{addressList.length}
						</span>
					</>
				}
				closeIcon={<i className='fa-solid fa-xmark' />}
				onCancel={onCancel}
				width={1000}
				footer={
					<Row justify={'space-between'} align={'middle'}>
						<Button
							className={'btn-default-rounded fsz-14 line-h-22 flex align-items-center'}
							onClick={() => {
								openAddressCreate(true)
							}}
							icon={<i className='fa-solid fa-plus mg-r-10' />}
						>
							{t('address.create-address-btn')}
						</Button>
						<Row justify={'end'}>
							<Button
								icon={<i className='fa-solid fa-xmark mg-r-10   '></i>}
								key='back'
								onClick={onCancel}
								className={'btn-default-outlined fsz-14 line-h-22 flex align-items-center'}
							>
								{t('address.button_cancel')}
							</Button>
							<Button
								icon={<i className='fa-solid fa-check mg-r-10'></i>}
								key='submit'
								loading={isLoading}
								onClick={() => {
									makeDeliveryAddress(selectedItem)
									onCancel()
								}}
								className={'btn-default-rounded fsz-14 line-h-22 flex align-items-center'}
							>
								{t('button.submit')}
							</Button>
						</Row>
					</Row>
				}
			>
				<Table
					rowKey={(record: any) => record.id}
					className={'address-table width100pc'}
					dataSource={addressList}
					columns={columns}
					pagination={false}
					scroll={{ y: '60vh', x: 'auto' }}
					onRow={(record: any, rowIndex: any) => {
						return {
							onClick: (event) => {
								setSelectedItem(record)
							},
						}
					}}
				/>
			</Modal>
			{isDelete && (
				<ConfirmModal
					content={t('address.delete-confirm-message')}
					visible={isDelete}
					loading={isLoading}
					onCancel={onCancelDelete}
					onSubmit={deleteAddress}
				/>
			)}
		</>
	)
}

export default Address
