import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TENANT_KEY } from "../../../core/config";
import { locationRepository } from "../../../repositories/LocationRepository";
import { localStorageRead } from "../../../utils/LocalStorageUtils";
import M24ErrorUtils from "../../../utils/M24ErrorUtils";
import './styles.scss';

const {Option} = Select;


type Props = {
    isVisible: any;
    onCancel: () => void;
    onSubmit: (body: any) => void;
    datasource?: any;
    isDefault?: boolean;
}

const layout = {
    labelCol: {span: 5,},
    wrapperCol: {span: 19},
};
const formTailLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 8, offset: 0 },
};

function CreateAddressModal(props: Props) {
    const {t} = useTranslation();
    const {
        isVisible, onCancel, onSubmit, isDefault, datasource} = props;

    const [form] = Form.useForm();
    const [locationState, setLocationState] = useState<any>();
    const [countries, setCountries] = useState<any>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();
    const [deliveryAddress,setDeliveryAddress] = useState<any>();
    const [locationConfig,setLocationConfig] = useState<any>(lodash.get(localStorageRead(TENANT_KEY),'config.locationConfig.countries',[]));
    const [countrySelected, setCountrySelected] = useState<any>();
    const [division, setDivision] = useState<any>();
    const [locationForm, setLocationForm] = useState<any>({});
    const [datasourceSelects, setDatasourceSelects] = useState<any>({});
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(()=>{
        getCountries();
    },[]);
    const getCountries = () => {
        locationRepository.getCountries().then(res => {
            let items: any[] = [];
            locationConfig.map((x: any)=>{
                let country = res.find((xx: any)=> xx.code===x.code);
                if(country){
                    items.push({
                        ...country,
                        requiredLevel: lodash.get(x,'requiredLevel',null),
                        preferNumberOfDivision: lodash.get(x,'preferNumberOfDivision',null)});
                }
            });
            setCountries(items);
        }).catch(err => {
            M24ErrorUtils.showError(t, err);
        });
    }
    useEffect(()=>{
        if(countries&& datasource){
            getDetail();
        }
    },[countries]);
    const getDetail= async ()=>{
        try{
            const res = await locationRepository.getDetail(lodash.get(datasource,'location',''));
            let temp: any = {};
            temp[res.level]= res.code;
            let parent = res.parent;
            while(parent){
                temp[parent.level]=parent.code;
                parent = parent.parent;
            }
            setLocationForm(temp);
            const resDivision: any = await locationRepository.getDivisions(temp[0]);
            let config = locationConfig.find((x: any)=> x.code===temp[0]);
            if(config){
                let group = lodash.chain(resDivision)
                    // Group the elements of Array based on `color` property
                    .groupBy("level")
                    .map((value, key) => {
                        if(value.length>config.preferNumberOfDivision){
                            value.splice(config.preferNumberOfDivision);
                        }
                        let name = '';
                        value.map((x: any,index: number)=>{
                            name = name +`${index>0?'/':''}` + x.name;
                        });
                        return {level: parseInt(key), name, items: value, require: parseInt(key)<= config.requiredLevel}
                    })
                    .value()
                setDivision(group);
                setCountrySelected(countries.find((x: any)=> x.code===temp[0]));
                let tempDatasourceList: any={};
                let keys = Object.keys(temp);
                //level cuối cùng không cần call api danh sách ở select
                if(keys.length>0) {
                    if(keys.length>group.length)
                    keys.splice(keys.length-1);
                }
                Promise.all(keys.map( async (key:any, index: number)=>{
                    const res = await locationRepository.getChildren(temp[key]);
                    return {level: parseInt(key)+1, values: res};
                })).then(values=>{
                    values.map((item: any)=>{
                        tempDatasourceList[item.level] = item.values;
                    });
                    setDatasourceSelects(tempDatasourceList);
                });
            }
        }catch (e){

        }
    }
    const getDivisions=(code: string)=>{
        locationRepository.getDivisions(code).then(res=>{
            let config = locationConfig.find((x: any)=> x.code===code);
            if(config){
                let group = lodash.chain(res)
                    // Group the elements of Array based on `color` property
                    .groupBy("level")
                    .map((value, key) => {
                        if(value.length>config.preferNumberOfDivision){
                            value.splice(config.preferNumberOfDivision);
                        }
                        let name = '';
                        value.map((x: any,index: number)=>{
                            name = name +`${index>0?'/':''}` + x.name;
                        });
                        return {level: parseInt(key), name, items: value, require: parseInt(key)<= config.requiredLevel}
                    })
                    .value()
                setDivision(group);
            }

        }).catch(err=>{

        });
    }
    const validateForm = () => {
        if(!countrySelected||(countrySelected &&!locationForm[countrySelected.requiredLevel])) return false;
        return true;
    }

    useEffect(() => {
        if (selectedItem) {
            form.setFieldsValue({
                receiver: lodash.get(selectedItem, 'receiver'),
                phoneNumber: lodash.get(selectedItem, 'phoneNumber'),
                location: lodash.get(selectedItem, 'location'),
                isDefault: lodash.get(selectedItem, 'isDefault'),
                name: lodash.get(selectedItem, 'name'),
                address1: lodash.get(selectedItem, 'address1'),
            });
        }
    }, [form, selectedItem]);

    const selectValueOfLevel=(level: any, value: any)=>{
        let temp = lodash.cloneDeep(locationForm);
        temp[level] = value;
        Object.keys(temp).map((key: any)=>{
            if(parseInt(key)>level){
                delete temp[key];
            }
        });
        setLocationForm(temp);
        let country = lodash.cloneDeep(countrySelected);
        if(level===0)
        {
            getDivisions(value);
            country = countries.find((x: any)=> x.code===value);
            setCountrySelected(country);
        }
        if(country&&country.requiredLevel<=level&&disabled)
        {
            let allValues = form.getFieldsValue();
            if(allValues.receiver&&allValues.phoneNumber&&allValues.address1)
            setDisabled(false);
        }
    }
    const getDatasourceOfLevel=(level: number, code: string)=>{
        locationRepository.getChildren(code).then(res=>{
            setDatasourceSelects({...datasourceSelects, [level]: res});
        }).catch(err=>{

        })
    }
    return (
        <Modal
            className={'_address-create-modal'}
            title={t('address.create-address')}
            open={isVisible}
            width={800}
            centered
            closeIcon={<i className="fa-solid fa-xmark" />}
            onCancel={() => {
                onCancel();
            }}
            footer={[
                <Button icon={<i className="fa-solid fa-xmark mg-r-10" />}
                        key="back" onClick={onCancel}
                        className={'btn-default-outlined fsz-14 flex align-items-center flex-nowrap'}>
                    {t('address.button_cancel')}
                </Button>,
                <Button className={'btn-default-rounded fsz-14 flex align-items-center flex-nowrap'}
                        icon={<i className="fa-solid fa-check mg-r-10" />} key="submit"
                        disabled={!validateForm() || isLoading || disabled}
                        loading={isLoading}
                        onClick={() => {
                           let keys = Object.keys(locationForm);
                           keys = keys.sort().reverse();
                           let key: any = keys.find((key: string)=> locationForm[key]);
                           if(key)
                           onSubmit({...form.getFieldsValue(), location: locationForm[key]});
                        }}
                > {t('button.submit')}</Button>
            ]}
        >
            <Form
                {...layout}
                layout={'horizontal'}
                form={form}
                initialValues={{
                    receiver: lodash.get(datasource,'receiver',''),
                    phoneNumber: lodash.get(datasource,'phoneNumber',''),
                    isDefault: isDefault||lodash.get(datasource,'isDefault',false),
                    name: lodash.get(datasource,'name',''),
                    address1: lodash.get(datasource,'address1',''),
                }}
                colon={false}
                labelAlign={'left'}
                onValuesChange={(changedValues: any, allValues: any)=>{
                    if(!allValues.receiver||!allValues.phoneNumber||!allValues.address1) setDisabled(true);
                    else setDisabled(false);
                }}
            >
                <Form.Item
                    label={<span className={'txt-size-h7 robotoregular txt-color-black'}>{t('address.address-name')}:
                    </span>}
                    name="name"
                    className={'_name-input'}
                >
                    <Input autoFocus={true} placeholder={`${t('cart.placeholderAddress')}`} className='py-6 px-12 rad-4'/>
                </Form.Item>
                <Form.Item
                    label={<span className={'txt-size-h7 robotoregular txt-color-black'}>{t('address.customer-name')}: <span
                        className={'txt-color-red'}>*</span></span>}
                    name="receiver"
                    className={'_receiver-input'}
                >
                    <Input placeholder={`${t('cart.receivedName')}`} className='py-6 px-12 rad-4'/>
                </Form.Item>
                <Form.Item
                    label={<span className={'txt-size-h7 robotoregular txt-color-black'}>{t('address.customer-phoneNumber')}: <span
                        className={'txt-color-red'}>*</span></span>}
                    name="phoneNumber"
                    className={'_phone-number-input'}
                    normalize={(value, prevValue) => {
                        let raw =  value.replace(/[^0-9-+. ()]/g, "");
                        return raw;
                    }}
                >
                    <Input placeholder={`${t('cart.receivedPhone')}`} className='py-6 px-12 rad-4'/>
                </Form.Item>
                <Form.Item
                    label={<span className={'txt-size-h7 robotoregular txt-color-black'}>{t('address.address-national')}: <span
                        className={'txt-color-red'}>*</span></span>}
                    // name="nation"
                >
                    <Select
                        placeholder={t('address.select-nation')}
                        allowClear
                        onChange={(value: any) => {
                            selectValueOfLevel(0, value);
                            getDatasourceOfLevel(1, value);

                        }}
                        value={lodash.get(locationForm, '0',undefined)}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: string, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            countries.map((nation: any) => {
                                return <Option key={nation.code} value={nation.code}
                                               name={nation.name}>{nation.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                {division?division.map((x: any, index: number)=>{
                    return <Form.Item
                        key={index}
                        label={<span className={'txt-size-h7 robotoregular'}>{x.name}: <span
                            className={'txt-color-red'}>{x.require?'*':''}</span></span>}
                    >
                        <Select
                            placeholder={t('address.select-place-holder',{value: x.name})}
                            onChange={(value: any) => {
                                // getDistricts(value);
                                // setPickedLocation({...pickedLocation, level1: value,level2:null, level3:null})
                                selectValueOfLevel(x.level, value);
                                if(index<division.length-1)
                                    getDatasourceOfLevel(x.level+1, value);
                            }}
                            value={lodash.get(locationForm, x.level,undefined)}
                            disabled={!locationForm[x.level-1]}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: string, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {datasourceSelects&&datasourceSelects[x.level]?datasourceSelects[x.level].map((item: any) => {
                                return <Option key={item.code} value={item.code}>{item.name}</Option>
                            }):null}
                        </Select>
                    </Form.Item>
                }): null}
                <Form.Item
                    label={<span className={'txt-size-h7 robotoregular'}>{t('address.address-location')}:
                        <span
                        className={'txt-color-red'}> *</span></span>}
                    name="address1"
                >
                    <Input.TextArea
                        rows={4}
                        maxLength={1000}
                        className='rad-4'
                    />
                </Form.Item>
                <Form.Item
                    {...formTailLayout}
                    name="isDefault"
                    valuePropName="checked"
                    className="pdt0 mg-bt-0">
                    <Checkbox disabled={isDefault||(datasource&&datasource.isDefault)}>
                        <span className={'txt-size-h7 robotoregular txt-color-black'}>{t('address.set-default-address')}</span>
                    </Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateAddressModal;
