import { DatePicker, Space } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import React from 'react';

const { RangePicker } = DatePicker;



interface RangeTestI {
    setValuesDate: (val: any) => void
}

const RangePickerTest: React.FC<RangeTestI> = ({setValuesDate}) => {
    const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
        setValuesDate(dates)
        if (dates) {
          console.log('From: ', dates[0], ', to: ', dates[1]);
          console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        } else {
          console.log('Clear');
        }
      };
      return <Space direction="vertical" size={12}>
      <RangePicker
      placeholder={['', '']}
        ranges={{
          Today: [moment(), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
        }}
        showTime
        format="YYYY/MM/DD HH:mm:ss"
        onChange={onChange}
        onOpenChange={(open: boolean) => {
          console.log('open', open)
        }}
        onCalendarChange={(dates, dateStrings, info) => {
          console.log('value', dates, 'mode', dateStrings, 'info', info)
        }}
      />
    </Space>
}
  
export default RangePickerTest;