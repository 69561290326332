import React, { useState } from 'react'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import '../../resources/styles/components/input.styles.scss'
interface M22FloatLabelProps {
	label: string
	value: string
	isRange?: boolean
	labelRange?: string
}
const FloatLabel: React.FC<M22FloatLabelProps> = (props) => {
	const [focus, setFocus] = useState(false)
	const { switcher, currentTheme, status, themes } = useThemeSwitcher();

	const { children, label, value, isRange, labelRange } = props

	const labelClass = focus || (value && value.length !== 0) ? 'label label-float' : 'label'
	const labelRangeClass = isRange && (focus || (value && value.length !== 0))? 'label-range label-range-float' : 'label-range'
	return (
		<div className={`float-label ${currentTheme}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
			{children}
			<label className={`${labelClass} `}>{label}</label>
			<label className={`${labelRangeClass} `}>{labelRange}</label>
		</div>
	)
}

export default FloatLabel

