import React from 'react'
import { useTranslation } from 'react-i18next'
import Badges from '../../../components/componentsAntd/Badges'
import TooltipCustom from '../../../components/componentsAntd/Tooltip'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
interface HeadingListProps {
	pagination: any
	typeShowData: string
	setTypeShowData: (val: string) => void
}
const HeadingList: React.FC<HeadingListProps> = (props) => {
	const { typeShowData, pagination, setTypeShowData } = props
	const { t } = useTranslation()
	return (
		<>
			<div className='dpl-flex align-items-center'>
				<div>
					<h3 className='robotomedium mg-0 dpl-block fsz-16 line-h-24 txt-color-black mg-r-4'>
						{t('breadcrumbs.orders')}
					</h3>
				</div>
				<div className='mg-l-5'>
					{pagination?.total > 0 && (
						<Badges color={'FCD535'} classnames={`px-8 py-4 dpl-block fsz-12 mg-r-20`}>
							{pagination?.total}
						</Badges>
					)}
				</div>
				<TooltipCustom title={<span className='fsz-12 line-h-20'>{t('shipments.listLayout')}</span>}>
					<span
						className={`${
							typeShowData === DATA_TABLE_LIST ? 'rad-4 bg-color-pink2' : ''
						} cursor-pointer mg-r-10 line-h-16  flex justify-content-center align-items-center w-26 h-26`}
					>
						<i
							className={`${
								typeShowData === DATA_TABLE_LIST ? 'txt-color-orange3' : ''
							} fsz-16 line-h-16 fa-light  fa-table-cells cursor-pointer txt-color-gray2`}
							onClick={() => setTypeShowData(DATA_TABLE_LIST)}
						></i>
					</span>
				</TooltipCustom>
				<TooltipCustom title={<span className=' fsz-12 line-h-20'>{t('shipments.tableLayout')}</span>}>
					<span
						className={`${
							typeShowData === DATA_TABLE_LAYOUT ? 'rad-4 bg-color-pink2' : ''
						} w-26 h-26 cursor-pointer line-h-16  justify-content-center align-items-center flex justify-content-center align-items-center`}
					>
						<i
							className={`fsz-16 fa-light fa-table-list  cursor-pointer txt-color-gray2 ${
								typeShowData === DATA_TABLE_LAYOUT ? 'txt-color-orange3' : ''
							}`}
							onClick={() => setTypeShowData(DATA_TABLE_LAYOUT)}
						></i>
					</span>
				</TooltipCustom>
			</div>
		</>
	)
}

export default HeadingList

