import { Select } from 'antd'
import React, { useState } from 'react'

const SelectTest: React.FC = () => {
    const [selected, setSelected] = useState('');
    
	const handleChange = (value: string) => {
		console.log(`selected ${value}`)
        setSelected(value)
	}
	return (
		<Select
			style={{ width: 200 }}
			onChange={handleChange}
            value={selected}
            showSearch
			placeholder='select option'
			options={[
				{
					value: 'jack',
					label: 'Jack',
				},
				{
					value: 'lucy',
					label: 'Lucy',
				},
				{
					value: 'disabled',
					disabled: true,
					label: 'Disabled',
				},
				{
					value: 'Yiminghe',
					label: 'yiminghe',
				},
			]}
		/>
	)
}

export default SelectTest

