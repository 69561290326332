import { FC, useEffect } from 'react'
import { Modal, Form, Button, FormInstance } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { t } from 'i18next'
import { PriceInput } from './PriceInput'

interface ModalConfigAllProps {
	showProgressBar: boolean
	isShowModalConfigAll: boolean
	setIsShowModalConfigAll: (show: boolean) => void
	onFinishFormConfigAll: (values: any) => void
}

const ModalConfigAll: FC<ModalConfigAllProps> = ({
	showProgressBar,
	isShowModalConfigAll,
	setIsShowModalConfigAll,
	onFinishFormConfigAll,
}: ModalConfigAllProps) => {
	const [form]: [FormInstance] = Form.useForm()

	const closeConfigAllModal = () => {
		if (!showProgressBar) {
			setIsShowModalConfigAll(false)
		}
	}

	const onFinish = (values: any): void => {
		onFinishFormConfigAll(values)
		form.setFieldsValue({
			priceFormula: '',
		})
	}

	useEffect(() => {
		if (!isShowModalConfigAll) {
			return
		}

		form.setFieldsValue({
			priceFormula: '',
		})
	}, [form, isShowModalConfigAll])

	return (
		<Modal
			className='modal-common'
			title={t('price-config.modal-config')}
			open={isShowModalConfigAll}
			onCancel={closeConfigAllModal}
			closeIcon={<i className='fal fa-times'></i>}
			footer={null}
			centered>
			<Form onFinish={onFinish} form={form}>
				<PriceInput
					label={t('price-config.price')}
					name='priceFormula'
					autoFocus
					disableInput={showProgressBar}
				/>

				<div className='ant-modal-footer mgt16'>
					<Button className='btn-default-outlined' onClick={closeConfigAllModal} key='cancel'>
						<i className='fal fa-times mgr10'></i>
						{t('button.cancel')}
					</Button>

					<Button
						className='btn-default-rounded'
						key='submit'
						type='primary'
						htmlType='submit'
						icon={<i className='fal fa-check mgr10'></i>}
						loading={showProgressBar}
						>
						{t('button.submit')}
					</Button>
				</div>
			</Form>
		</Modal>
	)
}

export { ModalConfigAll }

