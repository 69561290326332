import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PROFILE_KEY } from '../../core/config';
import { localStorageRead } from '../../utils/LocalStorageUtils';
import './style.scss';
const { Group } = Radio;
const { Item } = Form;
export interface ModalAddSupplierProps {
	isVisible: boolean;
	domain?: any;
	isLoading: boolean;
	onCancel: () => void;
	onSubmit: (body: any) => void;
	selectedSupplier?: any;
	isReconnect?: any;
	onReconnectSubmit: (body: any) => void;
	onSubmitAccountNotExist: (body: any, provider: string ) => void;
	provider: string;
	providerName: string
}

const ModalAddSupplier = (props: ModalAddSupplierProps) => {
	const {isVisible,isLoading,onCancel,onSubmit,domain,selectedSupplier,isReconnect,onReconnectSubmit,provider,onSubmitAccountNotExist, providerName
	} = props;
	const { t } = useTranslation();
	const navigate = useNavigate()
	// const [visible, setVisible] = useState<boolean>(isVisible);
	const [body, setBody] = useState<any>({ domain: domain ? domain : '' });
	const [phone, setPhone] = useState(lodash.get(localStorageRead(PROFILE_KEY), 'mobile'));
	const [phoneValid, setPhoneValid] = useState(true);
	const [requiredPhoneMess, setRequiredPhoneMess] = useState('');

	const [typeAddAccount, setTypeAddAccount] = useState('ACCOUNT_EXIST');

	useEffect(() => {
		if (selectedSupplier && isReconnect)
			setBody({
				domain: lodash.get(selectedSupplier, 'domain'),
				name: lodash.get(selectedSupplier, 'name'),
				// ...body,
			});
	}, []);
	/**
	 * @param key
	 * @param value
	 */
	const onChange = (key: string, value: any) => {
		if (key === 'domain' || key === 'username' || key === 'password') {
			setBody({ ...body, [key]: value });
		} else {
			setBody({ ...body, [key]: value.replace(/[^\d]/g, '') });
		}
	};
	/**
	 * validate dữ liệu hợp lệ
	 */
	const validate = () => {
		if (!body.domain || !body.username || !body.password || !body.secret)
			return false;
		return true;
	};

	const phoneValidation = (str: string) => {
		// from Reg101
        const regex = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g
        return !(!str || regex.test(str) === false);
	};

	const handleChangeTypeAddAccount = (e: any) => {
		setTypeAddAccount(e.target.value);
	};

	return (
		<Modal
			open={isVisible}
			title={<span className='robotoregular txt-color-black fsz-16 txt-capitalize'>{isReconnect ? t('supplier.reconnectAccountTitle'): t('supplier.addAccountTitle')}</span>}
			onCancel={onCancel}
			footer={[
				<Button
					icon={<CloseOutlined />}
					key='back'
					onClick={onCancel}
					className={'btn-default-outlined py-5 px-15'}>
					{t('button.cancel')}
				</Button>,
				<Button
					icon={<CheckOutlined />}
					key='submit'
					// ghost
					disabled={
						typeAddAccount === 'ACCOUNT_EXIST'
							? isLoading || (!isReconnect && !validate())
							: isLoading || (!isReconnect && !phoneValidation(phone))
					}
					loading={isLoading}
					onClick={() => {
						if (typeAddAccount === 'ACCOUNT_EXIST') {
							if (isReconnect) onReconnectSubmit(body);
							else onSubmit(body);
						} else {
							onSubmitAccountNotExist({phone}, provider);
						}
					}}
					className={'btn-default-rounded py-5 px-15'}>
					{t('button.submit')}
				</Button>,
			]}
			className='add-supplier'>
			<div>
				{!domain && (
					<Row className={'align-items-center'}>
						{!isReconnect && (
							<>
								<Col lg={8}>
									<span>
										{t('supplier.domain')}
										<span className={'mgl3 txt-color-red'}>*</span>
									</span>
								</Col>
								<Col lg={16}>
									<Input
										value={lodash.get(body, 'domain')}
										autoFocus={true}
										onChange={(e) => onChange('domain', e.target.value)}
										disabled={isReconnect}
									/>
								</Col>
							</>
						)}
					</Row>
				)}
				{!isReconnect && <>
					<Row className='mg-bt-16'>
						<Group onChange={handleChangeTypeAddAccount} value={typeAddAccount} className='robotoregular txt-color-black fsz-14'>
							<Radio value='ACCOUNT_EXIST'>{t('supplier.existedAccount')}</Radio>
							<Radio value='ACCOUNT_NOT_EXIST'>
								{t('supplier.not_exist_account')}
							</Radio>
						</Group>
					</Row>
				</>}
				{typeAddAccount === 'ACCOUNT_EXIST' ? (
					<>
						<Row className={'align-items-center mgt15'}>
							<Col lg={8}>
								<span className='robotoregular txt-color-black fsz-14 line-h-22'>
									{t('supplier.username')}
									<span className={'mgl3 txt-color-red'}>*</span>
								</span>
							</Col>
							<Col lg={16}>
								<Input
									value={lodash.get(body, 'name')}
									onChange={(e) => onChange('username', e.target.value)}
									disabled={isReconnect}
									className='border-radius4'
									placeholder={`${t('supplier.usernamePlaceholder')}`}
								/>
							</Col>
						</Row>
						<Row className={'align-items-center mgt15'}>
							<Col lg={8}>
								<span className='robotoregular txt-color-black fsz-14 line-h-22'>
									{`${t('supplier.password')}:`}
									<span className={'mgl3 txt-color-red'}>*</span>
								</span>
							</Col>
							<Col lg={16}>
								<Input.Password
									value={lodash.get(body, 'password')}
									onChange={(e) => onChange('password', e.target.value)}
									className='border-radius4'
									placeholder={`${t('supplier.passwordPlaceholder')}`}
									suffix={<i className="far fa-eye-slash"></i>}
								/>
							</Col>
						</Row>
						<Row className={'align-items-center mgt15'}>
							<Col lg={8}>
								<span className='robotoregular txt-color-black fsz-14 line-h-22'>
									{t('supplier.pin')}
									<span className={'mgl3 txt-color-red'}>*</span>
								</span>
							</Col>
							<Col lg={16}>
								<Input.Password
									value={lodash.get(body, 'secret')}
									onChange={(e) => onChange('secret', e.target.value)}
									className='border-radius4'
									placeholder={`${t('supplier.secretPlaceholder')}`}
									suffix={<i className="far fa-eye-slash"></i>}
								/>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row>
							<Col lg={8} className='dpl-flex align-items-center'>
								<span className='robotoregular txt-color-black fsz-14 line-h-22'>
									{`${t('supplier.phone')}:`}
									<span className={'mgl3 txt-color-red'}>*</span>
								</span>
							</Col>
							<Col lg={16} className='phone'>
								<Input
									value={phone}
									onChange={(e) => {
										setPhone(e.target.value)
										const isPhoneValid = phoneValidation(e.target.value);
										setPhoneValid(isPhoneValid)
										setRequiredPhoneMess(isPhoneValid
											? ""
											: `${t('supplier.requirePhoneMess')}`)
									}}
									className='border-radius4'
								/>
								{!phoneValid && <span className='txt-color-red robotoregular fsz-12 line-h-20 messages-validate'>
								{requiredPhoneMess}
							</span>}
							</Col>
						</Row>
						<div className={`dpl-flex align-items-center justify-content-center pd-r-16 ${phoneValid ? 'mg-t-16' : 'mg-t-32'}`}>
							<div className='dpl-flex align-items-center justify-content-center pd-16 bd-left-blue-7'>
								<i className="fa-solid fa-circle-info txt-color-blue6"></i>
							</div>
						<div className='robotoregular txt-color-black fsz-14'><span dangerouslySetInnerHTML={{__html: t('supplier.notifyAddNewAccount', {providerName})}}></span></div>
					</div>
				</>
				)}
			</div>
		</Modal>
	);
};
export default ModalAddSupplier;
