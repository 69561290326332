// import './badges.scss'
interface BadgesProps {
	color?: string
	children: React.ReactNode
	classnames?: string //font-size, padding, ...
	icon?: any
}
const Badges: React.FC<BadgesProps> = ({ icon, children, color = 'gray', classnames }) => {
	return (
		<span className={`badges badges-bgc__${color} ${classnames}`}>
			{icon ? icon : ''}
			{children}
		</span>
	)
}

export default Badges

