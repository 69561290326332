import React, { useCallback, useEffect, useRef, useState } from 'react'
import MainLayout from '../../components/Layout'
import { useTranslation } from 'react-i18next'
import PendingProductList from '../../components/PendingProduct/List'
import { useSearchParams } from 'react-router-dom'
import { ConnectShopInterface } from '../../Interface/ConnectShopInterface'
import { DEFAULT_PAGE_SIZE } from '../../core/config'
import { Col, Modal, Row, Spin } from 'antd'
import PendingProductFilter from '../../components/PendingProduct/Filter'
import { warehouseRepository } from '../../repositories/ProductWarehouseRepository'
import { connectMarketRepository } from '../../repositories/ConnectMarketRepository'
import { IProductDetails } from '../../Interface/ProductDetailsInterface'
import M24Notification from '../../utils/M24Notification'
import { IMarketplace } from '../../Interface/MarketplaceInterface'
import { categoryRepository } from '../../repositories/CategoryRepository'

const { confirm } = Modal

interface IProductData {
	isLoading?: boolean
	total: number | null
	productList: any[]
}

type PendingProductProps = {
	channel?: string
}

function PendingProduct(props: PendingProductProps) {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [shopList, setShopList] = useState<ConnectShopInterface[] | any>([])
	const [selectedItems, setSelectItems] = useState<string[]>([])
	const [productData, setProductData] = useState<IProductData>({
		total: null,
		productList: [],
	})
	const fetchGetProducts = useRef<null | ReturnType<typeof setTimeout>>(null)
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
	const [marketplaces, setMarketplaces] = useState<IMarketplace[]>([])

	useEffect(() => {
		return () => {
			if (fetchGetProducts.current) {
				return clearTimeout(fetchGetProducts.current)
			}
		}
	}, [])

	useEffect(() => {
		getShopeeIntegrations()
		getMarketplaces()
	}, [])

	const getShopeeIntegrations = () => {
		setLoading(true)
		connectMarketRepository
			.getChannelShops(
				{
					offset: 0,
					limit: 100,
				},
				'shopee'
			)
			.then((response) => {
				setShopList(response.data)
			})
			.catch((err) => {
				console.log(err.response)
			})
			.finally(() => setLoading(false))
	}

	const getMarketplaces = () => {
		categoryRepository
			.getMarketPlaces()
			.then((res) => {
				setMarketplaces(res)
			})
			.catch((err) => {
				console.log(err.response.data)
			})
	}

	const getProductList = useCallback((filter: any) => {
		const { page, pageSize, currentPage, sort, order, ...rest } = filter
		const offset = (pageSize || 0) * (currentPage - 1 || page - 1 || 0)
		const limit = pageSize || DEFAULT_PAGE_SIZE
		setProductData((prevState) => ({
			...prevState,
			isLoading: true,
		}))
		warehouseRepository
			.getPendingProducts({
				offset,
				limit,
				channel: props.channel,
				...rest,
			})
			.then((res) => {
				setProductData((prevState) => ({
					...prevState,
					isLoading: false,
					total: parseInt(res.headers['x-total-count'] || '') || null,
					productList: res.data,
				}))
			})
			.catch((err) => {
				setProductData((prevState) => ({
					...prevState,
					isLoading: false,
				}))
			})
	}, [])

	useEffect(() => {
		if (fetchGetProducts.current) {
			clearTimeout(fetchGetProducts.current)
		}
		fetchGetProducts.current = setTimeout(() => {
			const currentPage = parseInt(searchParams.get('page') || '1')
			const pageSize = parseInt(searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`)
			getProductList({ ...Object.fromEntries(searchParams), currentPage, pageSize })
		}, 1000)
	}, [searchParams, confirmDelete, getProductList])

	const onClickDelete = (record?: IProductDetails) => {
		confirm({
			icon: null,
			title: <span className={'robotomedium txt-capitalize'}>{t('storedProduct.confirm-delete-title')}</span>,
			content: <span className={'robotoregular'}>{t('pendingProduct.confirm-delete-content')}</span>,
			cancelText: (
				<span>
					<i className='fa-solid fa-xmark mgl5 mgr10' />
					{t('storedProduct.confirm-cancelText')}
				</span>
			),
			cancelButtonProps: { className: 'btn-default-outlined' },
			okText: (
				<span>
					<i className='fa-solid fa-trash mgl5 mgr10' />
					{t('storedProduct.confirm-okText')}
				</span>
			),
			okType: 'danger',
			okButtonProps: { className: 'btn-default-rounded' },
			onOk() {
				handleDeletePendingProduct(record)
			},
		})
	}

	useEffect(() => {
		let cPage = parseInt(searchParams.get('page')!)
		let pSize = searchParams.get('pageSize')
		if (productData.productList.length < 1 && cPage >= 1) {
			setSearchParams({ ...searchParams, page: (cPage - 1).toString(), pageSize: pSize!.toString() })
		}
	}, [productData])

	const handleDeletePendingProduct = (record?: any | null) => {
		setConfirmDelete(true)
		if (record) {
			warehouseRepository
				.deletePendingProduct([record.id])
				.then(() => {
					M24Notification.messageSuccess(t('pendingProduct.delete-success'), '', 2)
					setSelectItems([])
				})
				.catch((err) => {
					M24Notification.messageError(t('pendingProduct.delete-failed'), '', 2)
				})
				.finally(() => setConfirmDelete(false))
		} else {
			warehouseRepository
				.deletePendingProduct(selectedItems)
				.then(() => {
					M24Notification.messageSuccess(t('pendingProduct.delete-success'), '', 2)
					setSelectItems([])
				})
				.catch((err) => {
					M24Notification.messageError(t('pendingProduct.delete-failed'), '', 2)
				})
				.finally(() => setConfirmDelete(false))
		}
	}

	return (
		<MainLayout title={t('pendingProduct.header-title')}>
			<Row gutter={[0, 12]} className='stored-products-container'>
				<Col xs={24}>
					<PendingProductFilter
						shopList={shopList}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
					/>
				</Col>
				<Col xs={24}>
					<Spin spinning={confirmDelete}>
						<PendingProductList
							{...productData}
							loading={loading}
							selectedItems={selectedItems}
							setSelectItems={setSelectItems}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							onClickDelete={onClickDelete}
							marketplaces={marketplaces}
							shopList={shopList}
						/>
					</Spin>
				</Col>
			</Row>
		</MainLayout>
	)
}
export default PendingProduct

