import { Avatar, Divider, Image, Row, Table } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import lodash, { groupBy, sumBy, values } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import { textFromData } from '../../../components/OrderDetail/Information'
import M24Pagination from '../../../components/Pagination/M24Pagination'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../components/SkeletonTable'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import NestedTable from './NestedTable'
import Badges from '../../../components/componentsAntd/Badges/index'
import TooltipCustom from '../../../components/componentsAntd/Tooltip'

interface ListProps {
	items: any
	filter: any
	accounts: any
	total: number
	page: number
	size: number
	idAcc: string | undefined
	showProgressBar: boolean
	hasError: boolean | null
	isSkeleton: boolean
	handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const { idAcc, items, handleFilter, accounts, total, page, size, showProgressBar, filter, hasError, isSkeleton } =
		props
	const [logo, setLogo] = useState('')
	const [provider, setProvider] = useState('')
	const [customer, setCustomer] = useState('')
	const [expandedRows, setExpandedRows] = useState<Array<any>>([])
	const [loading] = useState<{ [id: string]: any }>({})

	const classes = 'robotomedium fsz-14 line-h-22 txt-color-black whitespace'

	useEffect(() => {
		const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc)
		if (indexAcc > -1 && !showProgressBar && !hasError) {
			const logo = lodash.get(accounts[indexAcc], 'provider.logo', '')
			const provider = lodash.get(accounts[indexAcc], 'provider.code', '')
			const customer = lodash.get(accounts[indexAcc], 'name', '')
			setLogo(logo)
			setProvider(provider)
			setCustomer(customer)
		}
	}, [accounts, idAcc, showProgressBar, hasError])

	const columns = [
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('deliveryNote.code')}</span>,
			key: 'code',
			className: classes,
			width: 180,
			render: (record: any) => {
				return (
					<div className='dpl-flex align-items-center'>
						<i
							className={`far fa-angle-${
								lodash.includes(expandedRows, record.delivery_note.code) ? 'down' : 'right'
							} txt-color-orange2 w-8 line-h-22 cursor-pointer mg-r-6`}
						></i>
						<Paragraph
							className='robotomedium mg-0 txt-color-orange2 fsz-14 line-h-22 order-heading whitespace'
							copyable={{
								text: lodash.get(record, 'delivery_note.code'),
								icon: [
									<TooltipCustom
										title={
											<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>
										}
									>
										<i className='far fa-copy line-h-14 w-12 txt-color-orange2'></i>
									</TooltipCustom>,
									<TooltipCustom
										title={
											<span className='robotoregular fsz-12 txt-color-black line-h-20'>
												Copied
											</span>
										}
									>
										<i className='far fa-check fsz-12 line-h-14 w-12 txt-color-orange2'></i>
									</TooltipCustom>,
								],
								tooltips: [false, false],
							}}
						>
							{lodash.get(record, 'delivery_note.code')}
						</Paragraph>
					</div>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('deliveryNote.supplier')}</span>,
			key: 'account',
			className: classes,
			width: 260,
			render: (record: any) => {
				return (
					<div className='dpl-flex align-items-center whitespace'>
						<Avatar
							size={16}
							src={logo}
							className='bd-none-impt w-12 h-12 dpl-flex align-items-center mg-r-4'
						/>
						<span className={`${classes}  mg-r-4`}>{provider}</span>
						<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0 mg-r-4'} />
						<span className={classes}>{customer}</span>
					</div>
				)
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('deliveryNote.exportedTime')}</span>
			),
			key: 'exportedTime',
			className: classes,
			width: 180,
			render: (record: any) => {
				const exportedAt = lodash.isNil(lodash.get(record, 'delivery_note.exported_at'))
					? '---'
					: formatDateTime(lodash.get(record, 'delivery_note.exported_at'))
				return <span className={classes}>{exportedAt}</span>
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('deliveryNote.totalPackages')}</span>
			),
			key: 'totalPackage',
			className: classes,
			width: 150,
			align: 'right' as 'right',
			render: (record: any) => (
				<span className={classes}>{lodash.get(record, 'delivery_note.package_number')}</span>
			),
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('deliveryNote.totalWeight')}</span>
			),
			key: 'totalWeight',
			className: classes,
			width: 150,
			align: 'right' as 'right',
			render: (record: any) => {
				return (
					<span className={classes}>{`${formatNumber(
						lodash.get(record, 'delivery_note.package_weight')
					)}kg`}</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('deliveryNote.COD')}</span>,
			key: 'COD',
			width: '12%',
			className: classes,
			align: 'right' as 'right',
			render: (record: any) => {
				return (
					<span className={`${classes} robotomedium`}>
						{lodash.get(record, 'delivery_note.cod') === 0
							? '---'
							: formatNumber(lodash.get(record, 'delivery_note.cod'))}
					</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('deliveryNote.address')}</span>,
			key: 'address',
			className: classes,
			ellipsis: true,
			render: (record: string) =>
				`${textFromData(record, 'delivery_note.customer_receiver', '---')} - ${textFromData(
					record,
					'delivery_note.customer_address',
					'---'
				)}`,
		},
	]

	const handleChangePage = (filter: any) => {
		const { page, size, providerUsername } = filter
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					customer: providerUsername,
					page: (page - 1).toString(),
					size: size.toString(),
				},
				[],
				['pageSize']
			)
		)
	}
	const totalRendered = hasError ? 0 : total
	return (
		<div className='white-box dpl-flex flex-col pd-imp-12'>
			<div className='dpl-flex align-items-center mg-bt-13'>
				<div>
					<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>
						{t('deliveryNote.list')}
					</h3>
				</div>
				<div>
					{totalRendered > 0 ? (
						<Badges color={'FCD535'} classnames={`mg-l-5 px-8 py-4 fsz-12`}>
							{totalRendered}
						</Badges>
					) : (
						''
					)}
				</div>
			</div>
			<SkeletonTable
				loading={showProgressBar || isSkeleton}
				columns={columns as SkeletonTableColumnsType[]}
				className={`main-table table-expandable table-expandable-bordered selected-table main-table-orange-hovered nested-px-36 mg-bt-13 ${
					items?.length === 0 ? 'empty' : ''
				}`}
				rowCount={items?.length ? items?.length : 3}
			>
				<Table
					rowKey={(record: any) => record.delivery_note.code}
					columns={columns}
					dataSource={items}
					locale={{
						emptyText: (
							<EmptyDataFilter
								className={isSkeleton || showProgressBar ? 'dpl-none' : ''}
								description={
									!searchParams.get('query') &&
									!searchParams.get('exportedAtFrom') &&
									!searchParams.get('exportedAtTo')
										? t('deliveryNote.empty')
										: t('deliverynotes.filterEmpty')
								}
							/>
						),
					}}
					pagination={false}
					className='main-table table-expandable table-expandable-bordered selected-table main-table-orange-hovered nested-px-36 mg-bt-13'
					loading={showProgressBar}
					expandRowByClick={true}
					expandable={{
						expandedRowRender: (record) => {
							const items =
								lodash.get(record, 'delivery_note_packages') &&
								lodash.get(record, 'delivery_note_packages').length > 0
									? lodash.get(record, 'delivery_note_packages')
									: []
							const itemsFiltered = values(groupBy(items, (item: any) => item.order.code)).map(
								(item: any) => {
									if (item.length === 1) {
										return item[0]
									} else {
										const packagesGrouped = {
											order: { ...item[0].order },
											package: {
												code: lodash.map(item, 'package.code'),
												weight_net: sumBy(item, (o: any) => o.package.weight_net),
											},
										}
										return packagesGrouped
									}
								}
							)
							return <NestedTable items={itemsFiltered} loading={loading} record={record} />
						},
						expandIcon: ({ expanded, onExpand, record }) => null,
						columnWidth: 0,
						onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
					}}
					rowClassName={(record) =>
						lodash.includes(expandedRows, record.delivery_note.code)
							? 'active-row cursor-pointer'
							: 'data-row cursor-pointer'
					}
				/>
			</SkeletonTable>
			<Row justify={'end'} className={''}>
				{items && items.length > 0 && (
					<M24Pagination
						onPageChange={handleChangePage}
						filter={{ ...filter, page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-0'
					/>
				)}
			</Row>
		</div>
	)
}

export default List

