import { ReloadOutlined } from '@ant-design/icons'
import { Avatar,  Col, Divider, Grid, Input, Radio, RadioChangeEvent, Row, Select } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'antd';


type ClaimFilterProps = {
	loading: boolean
	providerAccounts: any[]
	selectedAccount: any
	ticketStatuses: any[]
	ticketSolutions: any[]
	handleSelectAccount: (acc: any) => void
	handleSkeleton: (val: boolean) => void
}

const defaultFilter = {
	publicState: '',
	solutionCode: '',
	code: '',
	providerCode: '',
	accountCode: '',
	accountId: '',
}
const { useBreakpoint } = Grid

function ClaimFilter(props: ClaimFilterProps) {
	const { t } = useTranslation()
	const [showFilter, setShowFilter] = useState<boolean>(true)
	const [filter, setFilter] = useState<any>(defaultFilter)
	const [searchParams, setSearchParams] = useSearchParams()
	const [showCleanFilter, setShowCleanFilter] = useState(false)
	const { xl, xxl } = useBreakpoint()
	const [expand, setExpand] = useState(false)
	const [ticketType, setTicketType] = useState('ALL')
	const ref = useRef<HTMLDivElement | null>(null)

	const {
		providerAccounts,
		selectedAccount,
		ticketStatuses,
		ticketSolutions,
		handleSelectAccount,
	} = props

	useEffect(() => {
		setFilter({
			...defaultFilter,
			publicState: searchParams.get('publicState')?.split(',') || [],
			solutionCode: searchParams.get('solutionCode')?.split(',') || [],
			code: searchParams.get('code') || '',
			providerCode: searchParams.get('providerCode') || '',
			accountCode: searchParams.get('accountCode') || '',
		})

		// setExpand(ticketSolutions.length > 0 && ticketStatuses.length > 0 ? false : true)
	}, [searchParams, ticketSolutions.length, ticketStatuses.length])

	const handleChangeFilter = (param: string, value: any) => {
		const newFilter = {
			...filter,
			[param]: value,
		}
		setFilter(newFilter)
	}

	const handleChangeProviderAccount = (accountId: any) => {
		const acc = lodash.find(providerAccounts, (item) => item.id === accountId)
		// filter.accountId = accountId
		filter.accountCode = acc.name
		filter.providerCode = lodash.get(acc, 'provider.code')
		filter.ticketType = ticketType
		setFilter(filter)

		setSearchParams(
			lodash.omitBy(
				{
					...filter,
					accountCode: acc.name,
					providerCode: lodash.get(acc, 'provider.code'),
					ticketType,
				},
				(o, k) => !o
			)
		)
		handleSelectAccount(acc)
	}

	const handleSubmit = () => {
		setSearchParams({
			...lodash.omitBy(
				{ ...filter, publicState: filter.publicState.join(','), solutionCode: filter.solutionCode.join(',') },
				(o, k) => !o
			),
		})
	}

	const handleChangeFilterAndSubmit = (param: string, value: any, isSelect?: boolean) => {
		if (param === 'publicState' || param === 'solutionCode') {
			const itemUpdated = isSelect
				? [...filter[`${param}`], value]
				: filter[`${param}`].filter((item: any) => item !== value)
			setFilter({ ...filter, [param]: itemUpdated })
		} else {
			setFilter({ ...filter, [param]: value })
			setSearchParams({
				...lodash.omitBy({ ...filter, [param]: value }, (o, k) => !o),
				ticketType,
			})
		}
	}

	const handleResetFilter = () => {
		setFilter(defaultFilter)
		setSearchParams({})
		setTicketType('')
	}

	useEffect(() => {
		const showFilterByTicketType = ticketType === 'ALL' || isEmpty(ticketType) ? false : true
		if (ticketType !== 'ALL') {
			setShowCleanFilter(
				!lodash.isEmpty(
					lodash.pickBy(filter, (val: any, key: string) => {
						if (isArray(val)) {
							return val.every((e) => e) && !isEmpty(val)
						}
						return !isEmpty(val)
					})
				) ||
					!isEmpty(ticketType) ||
					showFilterByTicketType
			)
		}
	}, [filter, ticketType])

	const handleChangeTypeOfClaim = (e: RadioChangeEvent) => {
		setTicketType(e.target.value)
		setFilter({ ...filter, ticketType: e.target.value })
		// setSearchParams({
		// 	...lodash.omitBy({ ...filter, ticketType: e.target.value === 'ALL' ? '' : e.target.value }, (o, k) => !o),
		// })
	}

	return (
		<div className={'border-card-header pd12 mgbt18 ticket-filter-container border-radius4 mg-bt-12'}>
			<div
				className={`dpl-flex cursor-pointer ${showFilter ? 'mg-bt-8' : ''}`}
				onClick={() => setShowFilter(!showFilter)}
			>
				<span className={'claim-filter-title fsz-16 robotomedium txt-color-black txt-capitalize flex-grow-2'}>
					{t('tickets.tickets-filter-tittle')}
				</span>
				<span
					onClick={() => {
						setShowFilter(!showFilter)
					}}
					className={'cursor-pointer pdl5 align-self-center'}
				>
					<i className={`fas fa-chevron-${showFilter ? 'up' : 'down'} fsz-12`} />
				</span>
			</div>
			{showFilter && (
				<>
					<Row gutter={[24, 12]} align='middle'>
						<Col span={24}>
							<Row gutter={[24, 12]} align='middle'>
								{ticketStatuses?.length > 0 && (
									<Col flex={'0 0 auto'} ref={ref} className='flex align-items-baseline'>
										<span className={'txt-size-h7 robotoregular txt-color-black mg-r-4 whitespace'}>
											{t('tickets.ticket-statuses')}:
										</span>
										{ticketStatuses.map((status: any) => {
											const isSelect =
												filter.publicState && filter.publicState.includes(status.code)
													? true
													: false

											return (
												<span
													onClick={() => {
														handleChangeFilterAndSubmit(
															'publicState',
															status.code,
															!isSelect
														)
													}}
													className={`status-package ${isSelect ? 'active txt-color-black' : ''}`}
													key={status.code}
												>
													{status.name}
												</span>
											)
										})}
									</Col>
								)}
								{ticketSolutions?.length > 0 && (
									<Col flex={'0 0 auto'} className='flex align-items-baseline'>
										<span
											className={
												'claim-filter-form-label txt-size-h7 robotoregular txt-color-black mgr5 whitespace'
											}
										>
											{t('tickets.resolve-methods')}:
										</span>
										{ticketSolutions.map((solution: any) => {
											const isSelect =
												filter.solutionCode && filter.solutionCode.includes(solution.code)
													? true
													: false

											return (
												<span
													onClick={() => {
														handleChangeFilterAndSubmit(
															'solutionCode',
															solution.code,
															!isSelect
														)
													}}
													className={`cursor-pointer item ${isSelect ? 'item-selected' : ''}`}
													key={solution.code}
												>
													<span className={'txt-size-h8 robotomedium'}>{solution.name}</span>
												</span>
											)
										})}
									</Col>
								)}
							</Row>
						</Col>

						{expand && (
							<Col span={24}>
								<Row gutter={[24, 12]} align='middle'>
									<Col flex={`0 0 30%`} className='flex align-items-baseline'>
										<span
											className={'txt-size-h7 robotoregular txt-color-black  whitespace mg-r-4'}
										>
											{t('tickets.searchByCode')}:
										</span>
										<Input
											className={'input-layout width100pc'}
											placeholder={`${t('tickets.searchByCode-placeholders')}`}
											onChange={(event: any) => {
												handleChangeFilter('code', event.target.value)
											}}
											value={lodash.get(filter, 'code', '')}
											onPressEnter={(event: any) =>
												handleChangeFilterAndSubmit('code', event.target.value)
											}
										/>
									</Col>

									<Col flex='0 0 auto' className='flex align-items-baseline'>
										<span className={'txt-size-h7 robotoregular txt-color-black mg-r-8 whitespace'}>
											{`${t('shipment.typeOfClaim')}:`}
										</span>
										<Radio.Group onChange={handleChangeTypeOfClaim} value={ticketType}>
											<Radio value='ALL'>{t('claim.allType')}</Radio>
											<Radio value='ORDER'>{t('shipment.orderClaim')}</Radio>
											<Radio value='SHIPMENT'>{t('shipment.shipmentClaim')}</Radio>
										</Radio.Group>
									</Col>
									<Col flex={'0.4 0 auto'} className='flex align-items-center'>
										<span className={'txt-size-h7 robotoregular txt-color-black whitespace mg-r-4'}>
											{t('tickets.provider-account')}:
										</span>
										<Select
											className={`width100pc ${xl && !xxl ? 'mg-bt-0' : ''}`}
											placeholder={`${t('tickets.provider-account')}`}
											value={filter.accountId || lodash.get(selectedAccount, 'id')}
											onChange={(value) => {
												handleChangeProviderAccount(value)
											}}
										>
											{providerAccounts.map((account: any) => {
												return (
													<Select.Option key={account.id} value={account.id} className={''}>
														<div className={'dpl-flex align-items-center '}>
															<Avatar
																size={12}
																src={lodash.get(account, 'provider.logo', '')}
																shape={'circle'}
																className='bd-none-impt'
															/>
															<span
																className={
																	'mgl5 robotoregular txt-size-h7 txt-capitalize'
																}
															>
																{lodash.get(account, 'provider.code', '')}
															</span>
															<Divider type={'vertical'} className={'bd-color-black'} />
															<span className={'robotoregular txt-size-h7'}>
																{lodash.get(account, 'name', '')}
															</span>
														</div>
													</Select.Option>
												)
											})}
										</Select>
									</Col>
								</Row>
							</Col>
						)}
					</Row>
					<Row align='middle' justify={'space-between'} className='mg-t-12'>
						{ticketSolutions.length > 0 && ticketStatuses.length > 0 ? (
							<span className='cursor-pointer flex align-items-center' onClick={() => setExpand(!expand)}>
								<i className={`fa-solid fa-angle-${expand ? 'up' : 'down'} mg-r-6 fsz-12`}></i>
								<span className='line-h-20 txt-color-black fsz-12'>
									{t(`shipment.${expand ? 'collapse' : 'noCollapse'}`)}
								</span>
							</span>
						) : (
							<div></div>
						)}
						<div className={'dpl-flex align-items-center justify-content-end'}>
							{showCleanFilter && (
								<span
									onClick={handleResetFilter}
									className={
										'mgr15 cursor-pointer txt-size-h8 robotoregular txt-color-gray refresh-filter-btn'
									}
								>
									<ReloadOutlined className={''} /> &nbsp;{t('filter.refresh')}
								</span>
							)}
							<Button
								className={'px-14 txt-capitalize robotomedium btn-default-rounded'}
								onClick={() => handleSubmit()}
							>
								{t('tickets.search-btn')}
							</Button>
						</div>
					</Row>
				</>	
			)}
		</div>
	)
}

export default ClaimFilter

