import { Button, Space, Switch, DatePicker, Input, Select, Divider } from 'antd'
import React, { useContext, useState } from 'react'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import MainLayout from '../../components/Layout'
import { AppThemeContext, AppThemeContextI } from '../../context/ThemeContext'
import '../../resources/styles/components//picker.styles.scss'
import { Typography } from 'antd'
import { M22Checkbox } from './Checkbox'
import M22Radio from './M22Radio'
import M22Modal from './M22Modal'
import FormItem from 'antd/es/form/FormItem'
import FloatLabel from './FloatLabel'
import SelectTest from './SelectTest'
import DatePickerTest from './DatePickerTest'
import TextArea from 'antd/lib/input/TextArea'
import RateTest from './RateTest'
import SwitchTest from './SwitchTest'
import DividerTest from './DividerTest'
import ModalTest from './ModalTest'
import RangePickerTest from './RangePickerTest'
import BadgeTest from './BadgeTest'
import TableTest from './TableTest'
import M24Pagination from '../../components/Pagination/M24Pagination'
const { RangePicker } = DatePicker
const { Title } = Typography
interface GuideLinePropsI {}
const GuideLine: React.FC<GuideLinePropsI> = () => {
	const [isDarkMode, setIsDarkMode] = React.useState(false);
	const { switcher, currentTheme, status, themes } = useThemeSwitcher();
	const toggleTheme = (isChecked: boolean) => {
		setIsDarkMode(isChecked);
		switcher({ theme: isChecked ? themes.dark : themes.light });
	  };
	// const { modeChangeHandler, mode } = themeCtx as AppThemeContextI
	const [darkMode, setDarkMode] = useState(false)
	const [isFocus, setIsFocus] = useState(false)
	const [blur, setBlur] = useState(false)
	const [value, setValue] = useState('');
	const [valuesDate, setValuesDate] = useState<any>([]);
	
	


	return (
		<MainLayout>
			 <h1>The current theme is: {currentTheme}</h1>
			<Switch checked={isDarkMode} onChange={toggleTheme} />
			<Switch checkedChildren='🌜' unCheckedChildren='🌞' checked={darkMode} onChange={toggleTheme} />
			<div className='flex flex-col gap-12 '>
				<span>Button</span>
				<Space>
					<Button type='primary'>Primary Button</Button>
					<Button>Default Button</Button>
					<Button type='dashed'>Dashed Button</Button>
					<Button type='primary' disabled>
						Primary Button
					</Button>
				</Space>
				<Space>
					<Button type='primary' size='small'>
						Primary Button
					</Button>
					<Button size='small'>Default Button</Button>
					<Button type='dashed' size='small'>
						Dashed Button
					</Button>
					<Button type='primary' disabled size='small'>
						Primary Button
					</Button>
				</Space>
				<Space>
					<Button type='text'>Text Button</Button>
					<Button type='link'>Link Button</Button>
				</Space>
			</div>
			<div className='flex flex-col gap-12'>
				<Space>
					<Title>h1. Ant Design</Title>
					<Title level={2}>h2. Ant Design</Title>
					<Title level={3}>h3. Ant Design</Title>
					<Title level={4}>h4. Ant Design</Title>
					<Title level={5}>h5. Ant Design</Title>
				</Space>
			</div>

			<div className='flex flex-col mg-bt-12'>
				<Space>
					<RangePicker onBlur={() => setBlur(true)} onFocus={() => setIsFocus(true)} className='m22-picker' />
				</Space>
			</div>
			<div className='flex flex-col'>
				<M22Checkbox />
			</div>
			<div className='flex flex-col mg-bt-12'>
				<M22Radio />
			</div>
			<M22Modal />

			<Space className=''>
				<FloatLabel label='Nhap ho va ten' value={value}>
					<Input placeholder='' value={value} onChange={e => setValue(e.target.value)}/>
				</FloatLabel>
				<FloatLabel label='Nhap ho va ten' value={value}>
					<SelectTest />
				</FloatLabel>

				<FloatLabel label='Chon ngay' value={value}>
					<DatePickerTest />
				</FloatLabel>

				<FloatLabel label='Nhap mo ta vao day' value={value}>
					<TextArea placeholder='' />
				</FloatLabel>

			</Space>
			<Space className=''>
				<RateTest />
				<SwitchTest />
				
			</Space>
			<Space className=' width100pc'>
				<DividerTest />
				<ModalTest />
				<FloatLabel label='Tới Ngày' value={valuesDate} isRange labelRange='Đến ngày'>
					<RangePickerTest setValuesDate={(val) => setValuesDate(val)}/>
				</FloatLabel>
				<BadgeTest />
			</Space>
			<TableTest />

		</MainLayout>
	)
}

export default GuideLine

