import { Modal, Button, Row, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import TooltipCustom from '../componentsAntd/Tooltip'
interface InlineEditProps {
	defaultValue?: any
	type?: 'input' | 'textarea' | 'textarea-modal'
	className?: string
	title: string
	loading?: boolean
	isInTab?: boolean
	editable?: boolean
	multiple?: boolean
	label?: string
	onSubmit?: (value: string, callback: () => void) => void
	onSubmitMultiple?: (label: string, value: string, callback: () => void) => void
}
const InlineEdit = (props: InlineEditProps) => {
	const { defaultValue, type, title, onSubmit, label, onSubmitMultiple } = props
	const [showModal, setShowModal] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [value, setValue] = useState<any>(defaultValue)

	const { t } = useTranslation()
	const handleCancel = () => {
		setShowModal(false)
		setValue(defaultValue)
	}
	const handleOk = () => {
		setLoading(true)
		if (onSubmitMultiple && label) {
			onSubmitMultiple(label, value, () => {
				setValue('')
				setShowModal(false)
				setLoading(false)
			})
		}
		if (onSubmit) {
			onSubmit(value, () => {
				setValue('')
				setShowModal(false)
				setLoading(false)
			})
		}
	}
	const clickEdit = () => {
		if (type === 'textarea-modal') {
			setShowModal(true)
			if (defaultValue) setValue(defaultValue)
		}
	}

	return (
		<div>
			<Row className={'dpl-flex align-items-center'}>
				{defaultValue && defaultValue.length > 30 ? (
					<Row className={'dpl-flex align-items-center'} align={'middle'}>
						<span className={`personal-note txt-size-h7 txt-color-black`}>
							{defaultValue ? defaultValue : '---'}
						</span>
						<TooltipCustom title={defaultValue}>
							<span>
								<i className='txt-size-h7 far fa-info-circle txt-color-orange mgl5'></i>
							</span>
						</TooltipCustom>
					</Row>
				) : (
					<span className={`personal-note txt-size-h7 txt-color-black`}>
						{defaultValue ? defaultValue : '---'}
					</span>
				)}
				<span onClick={clickEdit} className={'mgl5 _note-icon-custom'}>
					<i className='far fa-edit txt-color-orange txt-size-h8'></i>
				</span>
			</Row>
			{showModal && type === 'textarea-modal' ? (
				<Modal
					open={showModal}
					title={title}
					onCancel={handleCancel}
					className='modal-inline-edit'
					closeIcon={<i className='fa-solid fa-xmark'></i>}
					footer={[
						<Button
							icon={<i className='fa-solid fa-xmark mg-r-10' />}
							key='back'
							onClick={handleCancel}
							className='btn-default-outlined'
						>
							{t('button.cancel')}
						</Button>,
						<Button
							icon={<i className='fa-solid fa-check mg-r-10' />}
							key='submit'
							loading={loading}
							onClick={handleOk}
							className='btn-default-rounded'
						>
							{t('button.submit')}
						</Button>,
					]}
				>
					<Input.TextArea
						value={value}
						onChange={(e) => setValue(e.target.value)}
						rows={10}
						autoFocus={true}
						placeholder={`${t('edit.enterPlaceholder')}`}
					/>
				</Modal>
			) : null}
		</div>
	)
}
InlineEdit.defaultProps = {
	type: 'input',
}
export default InlineEdit
