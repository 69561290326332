import { Button, Card, Col, Grid, Input, Row } from 'antd'
import lodash, { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMarketplace } from '../../Interface/MarketplaceInterface'

interface IProps {
	searchParams: any
	setSearchParams: Dispatch<SetStateAction<any>>
	marketplaces: IMarketplace[]
}

const FilterList: React.FC<IProps> = (props) => {
	const { useBreakpoint } = Grid
	const { lg } = useBreakpoint()
	const { t } = useTranslation()

	const activeMarketplaceList = props.searchParams.get('marketplaces')?.split(',') || []

	const [productNameFieldValue, setProductNameFieldValue] = useState<string>('')
	const [showCleanFilter, setShowCleanFilter] = useState(false)
	const [expand, setExpand] = useState(true)

	useEffect(() => {
		const name = props.searchParams.get('name') || ''
		setProductNameFieldValue(name)
	}, [props.searchParams])

	const onResetFilter = (paramName: string = '') => {
		if (!paramName) {
			props.setSearchParams({})
			setProductNameFieldValue('')
			return
		}
		const { ...rest } = Object.fromEntries(props.searchParams)
		delete rest[paramName]
		props.setSearchParams({ ...rest })
	}

	const onSelectMarketplace = (marketplace: string) => {
		switch (marketplace !== '') {
			case true:
				let marketplaceList = [...activeMarketplaceList]
				if (marketplaceList.includes(marketplace)) {
					onResetFilter('marketplaces')
					return
				}
				marketplaceList = [marketplace]
				props.setSearchParams({
					...Object.fromEntries(props.searchParams),
					marketplaces: marketplaceList.join(','),
				})
				break
			default:
				onResetFilter('marketplaces')
				break
		}
	}

	const onProductNameFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setProductNameFieldValue(e.target.value)

	const findProductByName = () => {
		let filterTemp = {
			name: productNameFieldValue.trim() || '',
			marketplaces: activeMarketplaceList.join(','),
		}
		props.setSearchParams({
			...lodash.omitBy(filterTemp, (value) => !value),
		})
	}

	const handleSearch = () => findProductByName()

	useEffect(() => {
		setShowCleanFilter(!!productNameFieldValue || !isEmpty(activeMarketplaceList))
	}, [activeMarketplaceList, productNameFieldValue])

	return (
		<Card
			title={
				<div
					className='flex justify-content-between align-items-center line-h-24 cursor-pointer'
					onClick={() => setExpand(!expand)}
				>
					<span className='txt-capitalize'>{t('warehouse.searchProduct')}</span>
					<i
						className={`mg-r-6 fsz-12 fa-solid cursor-pointer fa-angle-${expand ? 'up' : 'down'}`}
						onClick={() => setExpand(!expand)}
					></i>
				</div>
			}
			className={`filter-list-container ${!expand ? 'collapse' : ''}`}
		>
			{expand && (
				<Row gutter={[0, 16]}>
					<Col xs={24}>
						<Row gutter={[32, 12]} align='middle'>
							<Col>
								<Row gutter={[6, 12]} align='middle'>
									<Col>
										<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
											Marketplace:
										</h4>
									</Col>
									<Col>
										<span
											className={`status-package mg-bt-0 ${
												activeMarketplaceList.length === 0 ? 'active txt-color-black' : ''
											}`}
											onClick={() => onSelectMarketplace('')}
										>
											{t('tickets.missing_quantity_all')}
										</span>
									</Col>
									{props.marketplaces.map((marketplace) => (
										<Col key={`/warehouse_stored_products_${marketplace.code}`}>
											<span
												className={`status-package mg-bt-0 ${
													activeMarketplaceList.includes(marketplace.code) ? 'active txt-color-black' : ''
												}`}
												onClick={() => onSelectMarketplace(marketplace.code)}
											>
												{marketplace.name}
											</span>
										</Col>
									))}
								</Row>
							</Col>
							<Col>
								<Row gutter={[4, 0]} align='middle'>
									<Col>
										<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
											{t('orderDetail.productName')}:
										</h4>
									</Col>
									<Col>
										<form onSubmit={handleSearch}>
											<Input
												type='text'
												className={`txt-size-h8 txt-color-secondary robotoregular border-radius4 line-h-20 ${
													lg ? 'input-fixed-width' : ''
												}`}
												placeholder={`${t('warehouse.fillProductNameField')}...`}
												onChange={onProductNameFieldValueChange}
												value={productNameFieldValue}
											/>
										</form>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col span={24} className='flex align-items-center justify-content-end'>
						{showCleanFilter && (
							<Col>
								<Button
									type='text'
									className='btn-filter-reset txt-size-h8 robotoregular txt-color-secondary'
									icon={<i className='fa-solid fa-rotate-left' />}
									onClick={() => onResetFilter()}
								>
									{t('filter.refresh')}
								</Button>
							</Col>
						)}
						<Col>
							<Button
								className='btn-default-rounded txt-size-h7 robotomedium line-h-22 px-28 py-3 border-radius4'
								onClick={handleSearch}
							>
								{t('orders.button_search')}
							</Button>
						</Col>
					</Col>
				</Row>
			)}
		</Card>
	)
}

export default FilterList

