import { Avatar, Col, Row, Table } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import lodash from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import TooltipCustom from '../../../components/componentsAntd/Tooltip'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import HeadingList from './HeadingList'
import RowExtended from './RowExtended'

interface DataTableProps {
	items: any
	providers: any
	statuses: any
	pagination: any
	typeShowData: string
	handleTypeShowData: (val: string) => void
}
const DataTable: React.FC<DataTableProps> = (props) => {
	const { items, providers, statuses, pagination, typeShowData, handleTypeShowData } = props
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()

	const columns = [
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.codeTitle')}</span>,
			key: 'code',
			width: 200,
			render: (record: any) => {
				const provider = providers.find((x: any) => x.code === record.providerM1)
				let currency = lodash.get(record, 'provider.currency', '')
				if (currency) {
					currency = JSON.parse(currency)
					currency = currency.code
				}
				const logo = lodash.get(provider, 'logo', '')
				return (
					<div className='flex flex-col'>
						<div className='flex mg-bt-6'>
							<Link to={`/orders/${record.code}`} className='mg-r-12'>
								<Avatar
									shape={'square'}
									size={60}
									src={lodash.get(record, 'image', '')}
									className={'rad-4'}
									style={{ border: 'none' }}
								/>
							</Link>
							<Row>
								<Col span={24} className='flex align-items-center'>
									<span className={'txt-color-primary mg-r-12'}>
										<Link to={`/orders/${record.code}`}>
											<Paragraph
												className='robotomedium mg-0 txt-color-blue6  fsz-14 line-h-22 order-heading whitespace'
												copyable={{
													text: record.code,
													icon: [
														<TooltipCustom
															title={
																<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																	Copy
																</span>
															}
															>
															<i className='far fa-copy line-h-14 w-12 txt-color-black'></i>
														</TooltipCustom>,
														<TooltipCustom
															title={
																<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																	Copied
																</span>
															}
															>
															<i className='far fa-check fsz-12 line-h-14 w-12 txt-color-black'></i>
														</TooltipCustom>,
													],
													tooltips: [false, false],
												}}>
												{record.code ? record.code : '---'}
											</Paragraph>
										</Link>
									</span>

									<span className={'fsz-14 line-h-22 robotoregular txt-color-black whitespace'}>
										<TooltipCustom
											className={'mgl4 txt-size-h9'}
											title={
												<span className={'txt-color-white txt-size-h8 robotoregular'}>
													{t('orders.tooltipQuantity')}
												</span>
											}
											>
											<span className='fsz-14 line-h-22 robotoregular'>{`${lodash.get(
												record,
												'orderedQuantity',
												'--'
											)}/${record.purchasedQuantity !== null ? record.purchasedQuantity : '--'}/${
												record.receivedQuantity != null ? record.receivedQuantity : '--'
											}`}</span>
										</TooltipCustom>
									</span>
								</Col>
								<Col span={24} className={'flex align-items-center'}>
									<div className='mg-r-24 flex align-items-center flex-nowrap'>
										<TooltipCustom
											title={
												<span className={'robotoregular fsz-14 line-h-22 txt-color-black'}>
													{t('orders.note')}
												</span>
											}
											className={'txt-color-black txt-size-h7'}
											>
											<i className='fal fa-file-alt txt-size-h8 line-h-22 txt-color-secondary mgr3' />
										</TooltipCustom>
										<TooltipCustom
											title={
												<span className={'robotoregular txt-size-h8 txt-color-white'}>
													{record.personalNote}
												</span>
											}
											className={'txt-color-black txt-size-h9 robotoregular'}
											>
											<span
												className={
													'fsz-14 line-h-22 txt-color-black personal-note personal-note_in-table whitespace cursor-pointer w-70'
												}>
												{record.personalNote ? record.personalNote : '--'}
											</span>
										</TooltipCustom>
										{/* {record.personalNote && record.personalNote.length > 30 ? (
										<Row className={'dpl-flex align-items-center  flex-nowrap'}>
											<span
												className={
													'fsz-14 line-h-22 txt-color-black personal-note personal-note_in-table'
												}>
												{record.personalNote ? record.personalNote : '--'}
											</span>
										
										</Row>
									) : (
										<span
											className={
												'fsz-14 line-h-22 txt-color-black personal-note personal-note_in-table whitespace'
											}
											style={{ maxWidth: '150px' }}>
											{record.personalNote ? record.personalNote : '--'}
										</span>
									)} */}
									</div>
									<div className={'align-items-center flex'}>
										<Avatar size={20} src={logo} shape={'circle'} style={{ border: 'none' }} />
										<span className={'mgl5 fsz-14 line-h-22 txt-color-gray4'}>
											{lodash.get(provider, 'code', '')}
										</span>
										<span className={'txt-color-gray4 mgl5 mgr5'}>|</span>
										<span className={'fsz-14 line-h-22 txt-color-gray4'}>
											{lodash.get(record, 'customer', '')}
										</span>
									</div>
								</Col>
							</Row>
						</div>
					
					</div>
				)
			},
		},

		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('orders.tooltipCreatedAt')}</span>,
			key: 'createdAt',
			align: 'right' as 'right',
			width: 180,
			render: (record: any) => {
				return (
					<span className={'fsz-14 line-h-22 robotoregular txt-color-black'}>
						{formatDateTime(lodash.get(record, 'createdAt', '--'))}
					</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipments.financeTotalFees')}</span>,
			key: 'amount',
			align: 'right' as 'right',
			width: 180,
			render: (record: any) => {
				let currency = lodash.get(record, 'provider.currency', '')
				if (currency) {
					currency = JSON.parse(currency)
					currency = currency.code
				}
				return (
					<span className={'fsz-14 line-h-22 robotomedium txt-color-blue6 mgl3'}>{`${formatMoneyByUnit(
						lodash.get(record, 'provisionalAmount', ''),
						currency
					)}`}</span>
				)
			},
		},

		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipments.financeTotalNeedToPaid')}</span>,
			key: 'totalUnpaid',
			align: 'right' as 'right',
			width: 180,
			render: (record: any) => {
				let currency = lodash.get(record, 'provider.currency', '')
				if (currency) {
					currency = JSON.parse(currency)
					currency = currency.code
				}
				const totalUnpaidStr = record.totalUnpaid ? formatMoneyByUnit(record.totalUnpaid, currency) : formatMoneyByUnit(0)
				return (
					<span className={'fsz-14 line-h-22 txt-color-red2 mgl3 robotomedium'}>{`${
						record.status !== 'CANCELLED' ? totalUnpaidStr : t('orders.cancelled')
					}`}</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipments.statusOfShipment')}</span>,
			key: 'status',
			align: 'right' as 'right',
			width: 180,
			render: (record: any) => {
				const status = statuses.find((x: any) => x.code === record.status)
				return (
					<span
						style={{ backgroundColor: lodash.get(status, 'color', '#09B8AF') }}
						className={'rad-25 item-align-center fsz-14 line-h-22 txt-color-white robotoRegular px-16 py-6'}>
						{lodash.get(status, 'name', '--')}
					</span>
				)
			},
		},
	]
	const components = { body: { row: RowExtended } }

	return (
		<Table
			columns={columns}
			dataSource={items}
			rowKey={(record: any) => record.id}
			className='table-improved'
			pagination={false}
			rowClassName={(record, idx) => {
				const rowClassesByIdx = idx % 2 === 0 ? 'row-dark' : 'row-light'
				return `${rowClassesByIdx}`
			}}
			components={components}
			onRow={(record, index) => {
				const rowClassesByIdx = index! % 2 === 0 ? 'row-dark' : 'row-light'
				return { ...record, index, rowClassesByIdx }
			}}
			title={() => <HeadingList pagination={pagination} typeShowData={typeShowData} setTypeShowData={handleTypeShowData} />}
			locale={{
				emptyText: (
					<EmptyDataFilter
						description={
							!searchParams.get('code') &&
							!searchParams.get('timestampFrom') &&
							!searchParams.get('timestampTo') &&
							!searchParams.get('marketplaces')
								? t('orders.filterEmpty')
								: t('orders.emptyData')
						}
						className='flex flex-col justify-content-center'
					/>
				),
			}}
		/>
	)
}

export default DataTable

