import { Button, Empty, Spin, Table, Tabs } from 'antd'
import lodash, { parseInt } from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ConnectShopModal from '../../components/ConnectMarket/ConnectShopModal'
import MainLayout from '../../components/Layout'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { connectMarketContext } from '../../context/ConnectMarketContext'
import { DEFAULT_PAGE_SIZE } from '../../core/config'
import {
	ConnectChannelInterface,
	ConnectShopInterface,
	IntegrationCreateCommand,
	ShopCriteria,
} from '../../Interface/ConnectShopInterface'
import { connectMarketRepository } from '../../repositories/ConnectMarketRepository'
import EmptyBlue from '../../resources/images/empty-blue1.png'
import M24Notification from '../../utils/M24Notification'
import EditShopModal from '../../components/ConnectMarket/EditShopModal'
import Badges from '../../components/componentsAntd/Badges'

interface Pagination {
	current?: number
	hideOnSinglePage?: boolean
	total?: number
	pageSize?: number
}

const CONNECT_STATUS = [
	{ code: 'PENDING', color: '#FBB739', name: 'connectMarket.connect-pending' },
	{ code: 'CONNECTED', color: '#09B2AA', name: 'connectMarket.connect-success' },
]

function MarketConnect() {
	const { t } = useTranslation()
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
	const [activateTab, setActivateTab] = useState<string>('')
	const [dataSource, setDataSource] = useState<any>([])
	const [pagination, setPagination] = useState<Pagination>({})
	const [filter, setFilter] = useState<ShopCriteria | any>({})
	const [showConnectModal, setShowConnectModal] = useState<boolean>(false)
	const [channels, setChannels] = useState<ConnectChannelInterface[]>([])
	const [searchParams, setSearchParams] = useSearchParams()
	const [fetchingDatasource, setFetchingDatasource] = useState<boolean>(false)
	const { w } = useContext(connectMarketContext)
	const [showEditModal, setShowEditModal] = useState<boolean>(false)
	const [dataEdit, setDataEdit] = useState<any>([])

	useEffect(() => {
		const getChannels = () => {
			setShowProgressBar(true)
			connectMarketRepository
				.getMarketChannel()
				.then((response) => {
					setChannels(response)
					setActivateTab(response[0].code)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => setShowProgressBar(false))
		}
		getChannels()
	}, [])

	useEffect(() => {
		if (!activateTab) return
		let channel = searchParams.get('channel') || activateTab
		let pageSize = parseInt(searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`)
		let page = parseInt(searchParams.get('page') || '1')
		setFilter({ pageSize, page, ...Object.fromEntries(searchParams) })
		getIntegrationShops({ channel, pageSize, page, ...Object.fromEntries(searchParams) })
	}, [searchParams, activateTab])

	const getIntegrationShops = (filter: ShopCriteria | any) => {
		setFetchingDatasource(true)
		const { page, pageSize, currentPage, sort, ...rest } = filter
		const offset = (pageSize || 0) * (currentPage - 1 || page - 1 || 0)
		const limit = pageSize || DEFAULT_PAGE_SIZE
		connectMarketRepository
			.getChannelShops({ ...rest, limit, offset }, activateTab)
			.then((response) => {
				let currentPage = lodash.get(response, 'headers.x-page-number')
				setDataSource(response.data)
				setPagination({
					hideOnSinglePage: true,
					current: parseInt(currentPage) + 1,
					total: parseInt(lodash.get(response, 'headers.x-total-count')),
					pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
				})
			})
			.catch((err) => {
				console.log(err.response)
			})
			.finally(() => setFetchingDatasource(false))
	}

	const handleChangeTab = (key: any) => {
		setActivateTab(key)
		setSearchParams({
			...Object.fromEntries(searchParams),
			page: filter.page,
			pageSize: filter.pageSize,
		})
	}

	const handleChangePage = (filter: ShopCriteria | any) => {
		setSearchParams({
			...Object.fromEntries(searchParams),
			page: filter.page,
			pageSize: filter.pageSize,
		})
		setFilter({ ...filter, size: filter.pageSize })
	}

	const handleConnectShop = (body: IntegrationCreateCommand) => {
		let checkConnect: any
		let windowReferrer: any
		const openConnectTab = (url: any) => {
			let windowFeatures = 'left=100,top=100,width=540,height=720'
			windowReferrer = w.open(url, '_blank', windowFeatures)
			return windowReferrer
		}
		setShowProgressBar(true)
		connectMarketRepository
			.connectShop(body)
			.then((response) => {
				openConnectTab(response)
				checkConnect = setInterval(() => {
					if (!windowReferrer.closed || !windowReferrer) return
					clearInterval(checkConnect)
					window.location.reload()
				}, 500)
				setShowConnectModal(false)
				setShowProgressBar(false)
			})
			.catch((err) => {
				let error: any = lodash.get(err, 'response.data')
				M24Notification.notifyError(t('message.titleFailed'), t(`connectMarket.${error.title}`), '', 2)
			})
	}

	const handleEditShop = (body: ConnectShopInterface) => {
		setShowProgressBar(true)
		connectMarketRepository
			.editShop(body)
			.then(() => {
				M24Notification.messageSuccess('Cập nhật tên hiển thị thành công', '', 2)
				setShowEditModal(false)
				setShowProgressBar(false)
				getIntegrationShops(filter)
			})
			.catch((err) => {
				let error: any = lodash.get(err, 'response.data')
				M24Notification.notifyError(
					t('message.titleFailed'),
					//  t(`connectMarket.${error.title}`),
					'Tên đã tồn tại vui lòng nhập tên khác',
					'',
					2
				)
				setShowEditModal(false)
				setShowProgressBar(false)
				getIntegrationShops(filter)
			})
	}

	const handleShowConnectModal = () => {
		setShowConnectModal(true)
	}

	const handleCancelConnect = () => {
		setShowConnectModal(false)
	}

	const handleShowModalEdit = (id: any, data: any) => {
		setDataEdit(data)
		setShowEditModal(true)
	}
	const handleCancelModelEdit = () => {
		setShowEditModal(false)
	}

	const columns = [
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4 whitespace'>
					{t('connectMarket.shopName')}
				</span>
			),
			dataIndex: 'shopName',
			key: 'shopName',
			width: '20%',
			render: (shopName: any) => {
				return <span className={'txt-color-primary txt-size-h7 robotomedium'}>{shopName}</span>
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4 whitespace'>
					{t('connectMarket.shopDisplayName')}
				</span>
			),
			dataIndex: 'name',
			key: 'name',
			width: '25%',
			render: (name: any) => {
				return <span className={'txt-color-black txt-size-h7 robotomedium wordbreakword'}>{name}</span>
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4 whitespace'>
					{t('connectMarket.shopSector')}
				</span>
			),
			dataIndex: 'country',
			key: 'country',
			width: '20%',
			render: (country: any) => {
				return <span className={'txt-color-black txt-size-h7 robotoregular'}>{country}</span>
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4 whitespace'>
					{t('connectMarket.connect-status')}
				</span>
			),
			dataIndex: 'status',
			key: 'status',
			width: '15%',
			render: (status: any) => {
				return (
					<span className={'dpl-flex'}>
						<span
							className={'status-badge'}
							style={{
								backgroundColor: lodash.get(
									CONNECT_STATUS.find((x) => x.code === status),
									'color'
								),
							}}
						>
							{t(
								lodash.get(
									CONNECT_STATUS.find((x) => x.code === status),
									'name',
									''
								)
							)}
						</span>
					</span>
				)
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4 whitespace'>
					{t('connectMarket.connectedAt')}
				</span>
			),
			dataIndex: 'timestamp',
			key: 'timestamp',
			width: '20%',
			render: (timestamp: any) => {
				return (
					<span className={'txt-color-black txt-size-h7 robotoregular'}>
						{timestamp ? moment(timestamp).format('HH:mm DD/MM/YYYY') : ''}
					</span>
				)
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4 whitespace'>
					{/* {t('connectMarket.connectedAt')} */} Thao tác
				</span>
			),
			align: 'center' as 'center',
			dataIndex: 'manipulation',
			key: 'manipulation',
			width: '10%',
			render: (id: any, data: any) => {
				return (
					<>
						<div className='dpl-flex justify-content-around '>
							<i
								onClick={() => handleShowModalEdit(id, data)}
								className='cursor-pointer fsz-14 txt-color-blue fa-light fa-gear'
							></i>
							<i className='cursor-pointer fsz-14 txt-color-red fa-light fa-trash'></i>
						</div>
					</>
				)
			},
		},
	]

	return (
		<>
			<MainLayout title={t('menu.connect-market')}>
				<div className={'connect-market-container'}>
					<Spin spinning={showProgressBar}>
						{channels && channels.length >= 1 ? (
							<Tabs type='card' defaultActiveKey={activateTab} onChange={handleChangeTab}>
								{channels.map((x: ConnectChannelInterface) => {
									return (
										<Tabs.TabPane tab={x.name} key={x.code}>
											<div className={'shop-list-header'}>
												<span className={'dpl-flex align-items-center'}>
													<div>
														<span className={'title'}>{t('connectMarket.shop-list')}</span>
													</div>
													<div>
														{dataSource?.length < 1 ? (
															<Badges
																color={dataSource?.length < 1 ? 'gray' : 'FCD535'}
																classnames={`mg-l-5 px-9 py-3 fsz-12`}
															>
																{pagination.total}
															</Badges>
														) : (
															''
														)}
													</div>
												</span>
												<Button
													className='btn-default-rounded'
													onClick={handleShowConnectModal}
												>
													<i className='fa-solid fa-plus mgr10' />
													{t('connectMarket.connect-shop-btn')}
												</Button>
											</div>
											<div className={'shop-list'}>
												{/*<Spin spinning={fetchingDatasource}>*/}
												<Table
													columns={columns}
													dataSource={dataSource}
													pagination={false}
													rowClassName={'custom-row-hover'}
													bordered={false}
													loading={fetchingDatasource}
													locale={{
														emptyText: (
															<Empty
																image={EmptyBlue}
																imageStyle={{ height: 40, color: '#3340B6' }}
																description={
																	<div
																		className={
																			'align-items-center dpl-flex justify-content-center'
																		}
																	>
																		<div className={'empty-description'}>
																			<span
																				className={
																					'txt-size-h7 robotoregular txt-color-gray4'
																				}
																			>
																				{t('connectMarket.empty-list')}
																			</span>
																			<Button
																				onClick={handleShowConnectModal}
																				className={
																					'btn-default-rounded border-radius4 mgt5'
																				}
																			>
																				<span className={'mgr10'}>
																					<i className='fa-regular fa-link txt-color-white' />
																				</span>
																				<span
																					className={
																						'txt-size-h7 robotoregular'
																					}
																				>
																					{t(
																						'connectMarket.connect-shop-btn'
																					)}
																				</span>
																			</Button>
																		</div>
																	</div>
																}
															></Empty>
														),
													}}
												/>
												{dataSource && dataSource.length >= 1 && (
													<M24Pagination
														className={'mgbt5 mgt15'}
														filter={{
															size: parseInt(filter.pageSize),
															page: parseInt(filter.currentPage),
														}}
														pagination={pagination}
														onPageChange={handleChangePage}
													/>
												)}
												{/*</Spin>*/}
											</div>
										</Tabs.TabPane>
									)
								})}
							</Tabs>
						) : (
							<div className={'align-items-center dpl-flex justify-content-center'}>
								<div className={'empty-description'}>
									<Empty
										description={
											<span className={'txt-size-h7 robotoregular txt-color-gray4'}>
												{t('connectMarket.empty-list')}
											</span>
										}
									/>
								</div>
							</div>
						)}
					</Spin>
					{showConnectModal && (
						<ConnectShopModal
							market={activateTab}
							showConnectModal={showConnectModal}
							onCancel={handleCancelConnect}
							handleConnectShop={handleConnectShop}
						/>
					)}

					{showEditModal && (
						<EditShopModal
							data={dataEdit}
							showConnectModal={showEditModal}
							onCancel={handleCancelModelEdit}
							handleEditShop={handleEditShop}
						/>
					)}
				</div>
			</MainLayout>
		</>
	)
}

export default MarketConnect

