import React, { useState } from 'react'
import { Button, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ConnectShopInterface } from '../../Interface/ConnectShopInterface'
import TooltipCustom from '../componentsAntd/Tooltip'

type EditShopModalProps = {
	data: any
	showConnectModal: boolean
	onCancel: () => void
	handleEditShop: (body: ConnectShopInterface) => void
}

function EditShopModal(props: EditShopModalProps) {
	const { t } = useTranslation()
	const { onCancel, handleEditShop, showConnectModal, data } = props
	const [name, setName] = useState<any>(data.name)
	const [err, setErr] = useState<boolean>(false)
	console.log(err)

	const handleSubmit = () => {
		const newData = { ...data, name: name.trim() }
		handleEditShop(newData)
	}

	const handleEnableSubmit = () => {
		if (name.toString().length >= 1) return true
		else return false
	}
	const validateInputName = (e: any) => {
		if (e.length < 30) {
			setName(e)
			setErr(false)
		} else {
			setErr(true)
		}
	}

	return (
		<Modal
			className={'connect-shop-modal'}
			// title={t('connectMarket.connect-shop-modal-title',{shop:market})}
			title='Cập nhật tên hiển thị'
			open={showConnectModal}
			onCancel={onCancel}
			closeIcon={<i className='fa-solid fa-xmark' />}
			footer={[
				<Button className={'btn-default-outlined'} onClick={onCancel}>
					<i className='fa-solid fa-xmark mgr5' />
					{t('connectMarket.cancel-connect-btn')}
				</Button>,
				<Button className={'btn-default-rounded'} disabled={!handleEnableSubmit()} onClick={handleSubmit}>
					<i className='fa-solid fa-check mgr5' />
					{/* {t('connectMarket.connect-btn')} */}
					Cập nhật
				</Button>,
			]}
			centered
		>
			<div className={'connect-shop-modal-input'}>
				<span>{t('connectMarket.shopDisplayName')}:</span>
				<Input
					autoFocus
					placeholder={`${t('connectMarket.dpName-placeholder')}`}
					value={name}
					name='name'
					onChange={(e) => validateInputName(e.target.value)}
					onPressEnter={handleSubmit}
				/>
				{/* <Input type='hidden' name='id' value={data.id}  /> */}
				{err ? (
					<TooltipCustom title='Không nhập quá 30 ký tự'>
						<i className='mg-l-10 fsz-18 txt-color-red fa-light fa-circle-info' />
					</TooltipCustom>
				) : (
					''
				)}
			</div>
		</Modal>
	)
}

export default EditShopModal

