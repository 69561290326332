import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Button, Card, Modal, Progress} from "antd";
import {useTranslation} from "react-i18next";
import {ConnectChannelInterface, ConnectShopInterface, ShopCriteria} from "../../../Interface/ConnectShopInterface";
import {connectMarketRepository} from "../../../repositories/ConnectMarketRepository";
import MarketChannelList from "../../../components/StoredProducts/PostToSale/ChannelMarketList";
import {useNavigate} from "react-router-dom";
import ShopeeTitle from "../../../resources/images/shopee.png"


type PostToSaleProps = {
    visible?: boolean;
    onCancel: () => void;
    handleMoveToPendingList: (force?: boolean) => void;
    isMoving: boolean;
    setIsMoving: Dispatch<SetStateAction<any>>;
    showError: boolean;
    errorContent: string;
    setShowError: Dispatch<SetStateAction<any>>;
    movingResult: boolean;
    successCount: number;
    isCheckedList: string[];
    setIsCheckedList: Dispatch<SetStateAction<any>>;
    checkedAll: boolean;
    setCheckAll: Dispatch<SetStateAction<any>>
    setFailedCount: Dispatch<SetStateAction<any>>;
    failedCount: number;
}

function PostToSale(props: PostToSaleProps) {
    const {t} = useTranslation();
    const [fetchingData, setFetchingData] = useState(false);
    const [channels, setChannels] = useState<ConnectChannelInterface[]>([]);
    const [integrations, setIntegrations] = useState<ConnectShopInterface[]>([]);
    const navigate = useNavigate();
    const [integrationCheckedList, setIntegrationCheckedList] = useState<ConnectShopInterface[]>([]);

    useEffect(() => {
        if (props.isCheckedList.length === integrationCheckedList.length && props.isCheckedList.length >= 1) {
            props.setCheckAll(true);
        } else {
            props.setCheckAll(false)
        }
    }, [props.isCheckedList]);

    useEffect(() => {
        getMarketChannel();
    }, []);

    const getMarketChannel = () => {
        setFetchingData(true);
        connectMarketRepository.getMarketChannel().then((response) => {
            setChannels(response)
        }).catch((err) => console.log(err.response))
            .finally(() => setFetchingData(false))
    }

    const getChannelIntegrations = (filter: ShopCriteria, channelCode: string) => {
        connectMarketRepository.getChannelShops(filter, channelCode)
            .then((response) => {
                setIntegrations(response.data);
                setIntegrationCheckedList(response.data);
            })
            .catch((err) => console.log(err.response));
    }
    const [percent, setPercent] = useState<number>(0);

    const handleSelectAll = (e: any) => {
        props.setCheckAll(!props.checkedAll);
        props.setIsCheckedList(integrationCheckedList.map((li: any) => li.id));
        if (props.checkedAll) {
            props.setIsCheckedList([]);
        }
    };

    const handleSelectCheckbox = (e: any) => {
        const {value, checked} = e.target;
        props.setIsCheckedList([...props.isCheckedList, value]);
        if (!checked) {
            props.setIsCheckedList(props.isCheckedList.filter(item => item !== value));
        }
    };

    useEffect(() => {
        if (props.isMoving) {
            let counter = 1;
            const interval = setInterval(() => {
                counter = counter + 5;
                setPercent(counter)
                if (counter >= 100) {
                    clearInterval(interval);
                }
            }, 100);
        }
    }, [props.isMoving]);

    return (
        <Modal
            centered
            open={props.visible}
            onCancel={()=>{
                if(!props.isMoving) props.onCancel();
            }}
            className={'post-to-sale-container'}
            title={<div
                className={'txt-size-h5 robotomedium txt-color-black'}>{t('storedProduct.post-to-sale-title')}</div>}
            closeIcon={<i className="fa-solid fa-xmark"/>}
            footer={null}
            // bodyStyle={{minHeight: 300}}
        >
            {props.isMoving &&
            <div className={'mgbt10'}>
                <Progress
                    className={'moving-item-progress-bar'}
                    percent={percent}
                    status={'normal'}
                />
            </div>}
            {!props.movingResult ?
                <MarketChannelList
                    loading={fetchingData}
                    channels={channels}
                    integrations={integrations}
                    getChannelIntegrations={getChannelIntegrations}
                    handleMoveToPendingList={props.handleMoveToPendingList}
                    onCancel={props.onCancel}
                    isMoving={props.isMoving}
                    showError={props.showError}
                    errorContent={props.errorContent}
                    setShowError={props.setShowError}
                    isCheckedList={props.isCheckedList}
                    checkedAll={props.checkedAll}
                    handleSelectAll={handleSelectAll}
                    handleSelectCheckbox={handleSelectCheckbox}
                />
                :
                <div>
                    <Card
                        className={'market-integration__channel'}
                        title={<div
                            className={'dpl-flex flex-row justify-content-between'}
                        >
                            <img src={ShopeeTitle} alt={''} style={{width:72,height:'auto'}}/>
                            <span
                                className={'navigate-to-pending-list'}
                                onClick={() => navigate(`/pending-products/shopee`)}
                            >
                            {t('pendingProduct.move-to-list')}</span>
                        </div>}
                        type={'inner'}
                        size={'small'}
                        bordered={false}
                    >
                        <div className={'dpl-flex flex-col pdl10'}>
                        <span className={'mgt10 mgbt10 txt-color-black robotomedium txt-size-h7'}>
                            <span>{t('pendingProduct.product-added')}:
                                <span style={{color:'#09B2AA'}}
                                      className={'mgl5'}>{props.isMoving ? '---' :props.successCount}</span>
                            </span>
                            <span className={'mgl30'}>
                                {t('pendingProduct.product-add-failed')}:
                                <span style={{color:'#F54255'}}
                                      className={'mgl5'}>{props.isMoving ? '---' :props.failedCount}</span>
                            </span>
                        </span>
                        </div>
                    </Card>
                    <div className={'dpl-flex justify-content-end'}>
                        <Button
                            className={'btn-default-rounded px-14 border-radius4'}
                            onClick={props.onCancel}
                            disabled={props.isMoving}
                            loading={props.isMoving}
                        >
                            <i className="fa-solid fa-xmark mgl5 mgr10"/>
                            {t('storedProduct.cancel-post-to-sale-btn')}
                        </Button>
                    </div>
                </div>

            }

        </Modal>
    )
}

export default PostToSale;
